import React, { useContext } from 'react';
import { Grid, TextField, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { NotesManagerContext } from './context/NotesContext';
import { CreateNoteService } from './services/NotesServices';
import { NotesSecondaryButton } from './styles';
import { rowStatus } from 'common/utils';
import { useCurrentUserInfo } from 'common/hooks';

export const NoteForm = ({ owner }) => {
  const { setCreateNote, refreshNotesList, setRefreshNotesList } = useContext(NotesManagerContext);
  const { register, handleSubmit, formState: { errors } } = useForm();
  const { enqueueSnackbar } = useSnackbar();
  const userInfo = useCurrentUserInfo()
  const onSubmit = async (data) => {
    data.createdAt = new Date().toISOString();
    data.createdBy = userInfo?.id;
    data.updatedBy = userInfo?.id;
    data.createdName = userInfo?.fullName;
    CreateNoteService(data)
      .then(() => {
        enqueueSnackbar('Nota registrada con exito!', { variant: `success` });
        setCreateNote(false);
        setRefreshNotesList(!refreshNotesList);
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: `error` });
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input type="hidden" {...register('noteStatus')} value={rowStatus.Active} />
        <input type="hidden" {...register('ownerType')} value={owner?.owner_type} />
        <input type="hidden" {...register('ownerId')} value={owner?.owner_id} />
        <Grid container spacing={2} sx={{ marginTop: 1 }}>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              label="Titulo"
              variant="outlined"
              name="title"
              size="small"
              error={Boolean(errors.title)}
              {...register('title', {
                required: { value: true, message: 'Campo Requerido' }
              })}
              type="text"
              fullWidth
              style={{ width: '100%' }}
              InputLabelProps={{ shrink: true }}
              inputProps={{ style: { fontSize: 10 }}}
            />
            {errors?.title && (
              <span style={{ color: 'red', fontSize: '.7em', marginLeft: '2%' }}>
                {errors.title.message}
              </span>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              label="Descripcion"
              variant="outlined"
              name="description"
              size="small"
              multiline
              rows={4}
              error={Boolean(errors.description)}
              {...register('description', {
                required: { value: true, message: 'Campo Requerido' }
              })}
              type="text"
              fullWidth
              style={{ width: '100%' }}
              InputLabelProps={{ shrink: true }}
              inputProps={{ style: { fontSize: 10 }}}
            />
            {errors?.description && (
              <span style={{ color: 'red', fontSize: '.7em', marginLeft: '2%' }}>
                {errors.description.message}
              </span>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Button variant="contained" style={NotesSecondaryButton} type="submit">
              Guardar
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};
