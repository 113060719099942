import React, { useState, useEffect } from 'react';
import {
  Button,
  Grid,
  Typography,
  Box,
  Tooltip,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  IconButton,
  Modal,
  Divider
} from '@mui/material';
import {
  PrimaryButton,
  StyledDivider,
  StyledDropZone,
  SectionTitle,
  modalStyle,
  MediaIconStyle
} from 'common/styles';
import { Storage } from 'aws-amplify';
import { useDropzone } from 'react-dropzone';
import { MediaComponent } from 'common/components';
import { useSnackbar } from 'notistack';
import { NotesManager, NotesManagerProvider } from 'common/components/notes';
import { Delete as DeleteIcon, Info as InfoIcon, Download as DownloadIcon } from '@mui/icons-material';
import { CommonFunctions } from 'common/utils';
import { useParams } from 'react-router-dom';

export function RightSideElements() {
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [showFilesList, setShowFilesList] = useState(false);
  const [eventMedia, setEventMedia] = useState([]);
  const [currentMedia, setCurrentMedia] = useState();
  const [openModal, setOpenModal] = useState(false);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  const showMediaDetail = (file) => {
    setCurrentMedia(file);
    setOpenModal(true);
  };

  useEffect(() => {
    if (acceptedFiles?.length > 0) {
      setShowFilesList(true);
    }
  }, [acceptedFiles]);

  const files = acceptedFiles?.map((file) => <li key={file.path}>{file.path}</li>);

  const handleDeleteMedia = (key) => {
    Storage.remove(key)
    .then(() => {
      enqueueSnackbar('Elemento eliminado con exito!', { variant: `success` });
      getEventMedia();
    })
    .catch(() => {
      enqueueSnackbar('Error al intentar borrar el elemento!', { variant: `error` });
    })
  };


  const getEventMedia = () => {
    Storage.list(`${params?.id}/`)
        .then(data => setEventMedia(data))
        .catch(err => console.log(err));
  };

  const uploadMedia = () => {
    if (params?.id === undefined) {
      enqueueSnackbar('No es posible cargar archivo. Debe crear el post primero!', { variant: `error` });
    }
    else {
      acceptedFiles?.map((file) => {
        Storage.put(`${params.id}/${file.name}`, file)
          .then (() => {
            enqueueSnackbar('Archivo cargado con exito!', { variant: `success` });
            getEventMedia();
            setShowFilesList(false);
          })
          .catch(() => {
            enqueueSnackbar('Error al intentar cargar el archivo!', { variant: `error` });
          });
      });
    }
  }

  useEffect(() => {
    getEventMedia();
  }, [params])

  return (
    <>
      <Grid item xs={12} sm={12} md={12}>
        <Grid item xs={12} sm={12} md={12} elevation={3} style={{ marginTop: 18 }}>
          <Typography style={SectionTitle}>Archivos del Post</Typography>
        </Grid>
        <Divider sx={StyledDivider} />
        <form>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <StyledDropZone {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} /> <br />
                Arrastre archivos aquí, o haga click.
              </StyledDropZone>
            </Grid>
            {showFilesList && (
              <Grid container spacing={2} style={{ marginTop: 2, marginBottom: 10 }}>
                <Grid item xs={12} sm={8} md={8}>
                  <ul>{files}</ul>
                </Grid>
              </Grid>
            )}
            <Grid item xs={12} sm={6} md={4}>
              <Button variant="contained" style={PrimaryButton} onClick={() => uploadMedia()}>Cargar</Button>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <ImageList cols={3}>
                  {eventMedia?.map((item) => (
                    <ImageListItem key={item.eTag} loading="lazy">
                      <Typography sx={{ fontSize: 10 }}>
                        {CommonFunctions.GetFileName(item.key)}
                      </Typography>
                      <MediaComponent file={item} />
                      <ImageListItemBar
                        actionIcon={
                          <>
                            <IconButton
                              sx={MediaIconStyle}
                              aria-label={`info about ${item.key}`}
                              onClick={() => showMediaDetail(item)}
                              key={`info-${item.key}`}
                            >
                              <Tooltip title="Ver">
                                <InfoIcon fontSize="small" />
                              </Tooltip>
                            </IconButton>
                            <IconButton
                              sx={MediaIconStyle}
                              aria-label={`download ${item.key}`}
                              key={`download-${item.key}`}
                              href={`${process.env.REACT_APP_BUCKET_URL}${item.key}`}
                            >
                              <Tooltip title="Descargar">
                                <DownloadIcon fontSize="small" />
                              </Tooltip>
                            </IconButton>
                            <IconButton
                              sx={MediaIconStyle}
                              aria-label={`delete ${item.key}`}
                              onClick={() => handleDeleteMedia(item.key)}
                              key={`delete-${item.key}`}
                            >
                              <Tooltip title="Borrar">
                                <DeleteIcon fontSize="small" />
                              </Tooltip>
                            </IconButton>
                          </>
                        }
                        sx={{ height: 22 }}
                      />
                    </ImageListItem>
                  ))}
                </ImageList>
                <Modal
                  open={openModal}
                  onClose={() => setOpenModal(false)}
                  aria-labelledby="parent-modal-title"
                  aria-describedby="parent-modal-description"
                >
                  <Box sx={{ ...modalStyle, width: { md: 800, xs: '95%' }, height: { md: 600, xs: '45%'}  }}>
                    <MediaComponent file={currentMedia} height="98%" width="100%" />
                    <Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>
                      {CommonFunctions.GetFileName(currentMedia?.key ?? '')}
                      <IconButton
                        aria-label={`download ${currentMedia?.key}`}
                        key={`download-${currentMedia?.key}`}
                        href={`${process.env.REACT_APP_BUCKET_URL}${currentMedia?.key}`}
                      >
                        <Tooltip title="Descargar">
                          <DownloadIcon fontSize="small" />
                        </Tooltip>
                      </IconButton>
                    </Typography>
                  </Box>
                </Modal>
            </Grid>
          </Grid>
          </form>
          {params?.id !== undefined && (
            <Grid item xs={12} sm={12} md={12}>
              <Typography style={SectionTitle}>Comentarios</Typography>
              <NotesManagerProvider>
                <NotesManager owner={{ owner_type: 'post', owner_id: params?.id }} />
              </NotesManagerProvider>
            </Grid>
          )}
      </Grid>
    </>
  )
}