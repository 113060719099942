import React, { useContext, useEffect, useState } from 'react';
import { CardContent, Grid, TextField, Box, Button, Divider, Typography } from '@mui/material';
import { AddCircle } from '@mui/icons-material';
import { DataTable } from 'common/components';
import { GridSearhField, GridButton, PageTitle, StyledDivider, StyledCard } from 'common/styles';
import { useOrganizationColumns } from '../hooks';
import { useNavigate } from 'react-router-dom';
import { GetOrganizationsService } from '../services';
import { useCurrentToken } from 'common/hooks';
import { AppContext } from 'contexts';

export function OrganizationsGrid() {
  const { refreshGrid } = useContext(AppContext);
  const [originalData, setOriginalData] = useState([]);
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const columns = useOrganizationColumns();
  const navigate = useNavigate();
  const requestToken = useCurrentToken();

  const gridSearch = (searchValue) => {
    const filteredData = originalData.filter((item) => {
      return Object.values(item).join('').toLowerCase().includes(searchValue.toLowerCase());
    });
    setData(filteredData);
  };

  useEffect(() => {
    GetOrganizationsService({ token: requestToken }).then((response) => {
      setOriginalData(response);
      setData(response);
      setLoadingData(false);
    });
  }, [refreshGrid]);

  return (
    <StyledCard elevation={1}>
      <Grid container>
        <Grid item xs={12} md={8}>
          <Typography style={PageTitle}>Organizaciones Registradas</Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box textAlign="right">
            <Button
              variant="contained"
              size="small"
              startIcon={<AddCircle />}
              style={GridButton}
              onClick={() => navigate('/organizaciones/agregar')}
            >
              Agregar
            </Button>
            <TextField
              placeholder="Buscar..."
              variant="standard"
              onChange={(e) => gridSearch(e.target.value)}
              style={GridSearhField}
              inputProps={{ style: { fontSize: 12 } }}
            />
          </Box>
        </Grid>
      </Grid>
      <Divider sx={StyledDivider} />
      <Grid container spacing={0}>
      <Grid item xs={12} sm={12} md={12}>
        <CardContent>
          <DataTable
            height={670}
            columns={columns}
            rows={data}
            loadedData={loadingData}
            pageRows={25}
            getRowId={(row) => row.Id}
          />
        </CardContent>
      </Grid>
    </Grid>

    </StyledCard>
  );
}
