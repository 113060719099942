import React, { useState } from 'react';
import { NotesManagerContext } from './NotesContext';

export const NotesManagerProvider = ({ children }) => {
  const [notes, setNotes] = useState([]);
  const [createNote, setCreateNote] = useState(false);
  const [refreshNotesList, setRefreshNotesList] = useState(false);

  return (
    <NotesManagerContext.Provider
      value={{
        createNote,
        notes,
        refreshNotesList,
        setCreateNote,
        setNotes,
        setRefreshNotesList
      }}
    >
      {children}
    </NotesManagerContext.Provider>
  );
};
