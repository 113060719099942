import React, { useContext } from 'react';
import { List, ListItem, ListItemButton, ListItemText, Typography, Divider, IconButton, Tooltip } from '@mui/material';
import { Delete as DeleteIcon, CheckCircle as CheckCircleIcon } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { NotesManagerContext } from './context/NotesContext';
import { Format, rowStatus }  from 'common/utils';
import { UpdateNote } from './services/NotesServices';

export const NotesList = () => {
  const { refreshNotesList, notes, setRefreshNotesList } = useContext(NotesManagerContext);
  const { enqueueSnackbar } = useSnackbar();

  const setNoteBaseParams = (note, status ) => {
    return {
      Id: note.Id,
      createdBy: note.createdBy,
      createdName: note.createdName,
      createdAt: note.createdAt,
      title: note.title,
      description: note.description,
      noteStatus: status,
      ownerId: note.ownerId,
      ownerType: note.ownerType,
      updatedBy: note.updatedBy
    }
  };

  const handleDeleteNote =(note) => {
    const params = setNoteBaseParams(note, rowStatus.Deleted);
    const id = params.Id;
    UpdateNote({ id, params }).then((response) => {
      if ('Id' in response) {
        enqueueSnackbar('Nota borrada con exito!', { variant: `success` });
        setRefreshNotesList(!refreshNotesList);
      }
    })
    .catch(() => {
      enqueueSnackbar('Se ha producido un error', { variant: `error` });
    })
  };

  const handleCompleteTask =(note) => {
    const params = setNoteBaseParams(note, rowStatus.Completed);
    const id = params.Id;
    UpdateNote({ id, params }).then((response) => {
      if ('Id' in response) {
        enqueueSnackbar('Tarea Completada con exito!', { variant: `success` });
        setRefreshNotesList(!refreshNotesList);
      }
    })
    .catch(() => {
      enqueueSnackbar('Se ha producido un error', { variant: `error` });
    })
  };

  return (
    <List sx={{ width: '100%', bgcolor: 'background.paper' }} aria-label="notes">
      {notes?.map((note) => (
        <>
          <ListItem
            key={`note-${note.id}`}
            secondaryAction={
              <>
                {note.noteStatus === rowStatus.Completed ? (
                  <Typography variant="span" key={`completed-${note.id}`} style={{ fontSize: 12 }}>
                    Completada
                  </Typography>
                ) : (
                  <>
                    {note.noteStatus === rowStatus.Active && (
                      <Tooltip title="Completada">
                        <IconButton edge="end" aria-label="completed" onClick={() => handleCompleteTask(note)}>
                          <CheckCircleIcon color="success" />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip title="Borrar">
                      <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteNote(note)}>
                        <DeleteIcon color="error" />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </>
            }
          >
            <ListItemButton key={note.Id + note.title}>
              <ListItemText key={note.createdAt + note.title}>
                <Typography variant="caption" gutterBottom component="div" key={`date${note.createdAt}`}>
                  {`${Format.DateTime(note.createdAt)} - ${note.createdName}`}
                </Typography>
                <Typography variant="caption" gutterBottom component="div" sx={{ fontWeight: 'bold' }}>
                  {`Título: ${note.title}`}
                </Typography>
                <Typography variant="span" key={note.description} gutterBottom component="div" style={{ fontSize: 12 }}>
                  {note?.description}
                </Typography>
                <Typography
                  variant="caption"
                  key={note.createdAt}
                  gutterBottom
                  component="div"
                  style={{ fontSize: 9 }}
                >
                </Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <Divider variant="inset" component="li" />
        </>
      ))}
    </List>
  );
};
