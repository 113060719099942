import React, { useContext, useState } from 'react';
import { Amplify, Auth } from 'aws-amplify';
import { Grid, Typography, Button, TextField, Box, CssBaseline } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { AppContext } from 'contexts';
import MuiPhoneNumber from 'material-ui-phone-number';
import { awsconfig } from '../config/aws-exports';
import {
  PrimaryButton,
  SessionBox,
  SessionBoxTitle,
  SessionFormInput,
  SessionHelperText,
  SessionLeftSide,
  SessionLogo
} from 'common/styles';
import { AuthErrorHandler } from '../utils';
import { CopyrightSection } from 'common/components';

Amplify.configure(awsconfig);

export const AuthLogin = () => {
  const { register, formState: { errors }, handleSubmit } = useForm();
  const { enqueueSnackbar } = useSnackbar();
  const { setCurrentUser, setIsIdle, setCurrentRole } = useContext(AppContext);
  const [phone, setPhone] = useState('');
  const navigate = useNavigate();
  const handleOnChange = (value) => setPhone(value);

  async function handleLogin (data)  {
    try {
      const user = await  Auth.signIn(phone, data.password);
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED' || user.challengeName === 'PasswordResetRequiredException') {
        await Auth.completeNewPassword(user, data.password);
      }

      if (user.signInUserSession !== undefined) {
        const userData = user?.signInUserSession?.idToken;
        const userInfo = {
          token: userData.jwtToken,
          info: userData.payload,
          username: userData.payload['cognito:username'],
          role: userData.payload['cognito:groups'].shift()
        };

        setIsIdle(true);
        setCurrentUser(userInfo);
        setCurrentRole(userInfo.role);
        navigate('/inicio');
      }
    }
    catch (error) {
      enqueueSnackbar(AuthErrorHandler.getErrorMessage(error), { variant: `error` });
    }
  }

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <CssBaseline />
      <Grid item xs={12} sm={12} md={6} style={SessionLeftSide}>
        <Box sx={SessionBox}>
          <form onSubmit={handleSubmit(handleLogin)}>
            <Box sx={SessionBoxTitle} justifyContent="center">
            <img src={`${process.env.PUBLIC_URL}/img/logo.jpg`} style={SessionLogo} />
            </Box>
            <Typography align="center" style={SessionHelperText}>Ingrese sus credenciales para continuar</Typography>
            <MuiPhoneNumber
              label="Ingrese su usuario"
              onChange={handleOnChange}
              defaultCountry={'mx'}
              autoFormat={false}
              variant="outlined"
              style={SessionFormInput}
            />
            {errors?.username && (
              <span style={{ color: 'red', fontSize: '.7em', marginLeft: '2%' }}>
                {errors?.username.message}
              </span>
            )}
            <TextField
              label="Ingrese su clave"
              {...register('password', { required: 'Campo Requerido' })}
              variant="outlined"
              type="password"
              style={SessionFormInput}
              error={!!errors?.password}
              helperText={errors?.password?.message}
            />
            <Button type="submit" variant="contained" style={PrimaryButton}>Iniciar sesión</Button>
            <CopyrightSection />
          </form>
        </Box>
      </Grid>
      <CssBaseline />
      <Grid
        item
        xs={0}
        sm={0}
        md={6}
        style={{
          display: 'block',
          backgroundImage: `url(${process.env.PUBLIC_URL}/img/social.jpg)`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100% 100%',
          backgroundPosition: 'center center'
        }}
      />
    </Grid>
  );
};
