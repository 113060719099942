import axios from 'axios';

const timeout = 10000;
const errorResponse = { data: { error: { code: 'A001', message: 'Servicio no disponible' } } };

export const GetAxiosInstance = (header, baseUrl, token) => {
  let headers = {};
  headers = header ?? {...headers, 'Content-Type': 'application/json' };
  headers = {...headers, Authorization: 'Bearer ' + token };

  return axios.create({
    baseURL: baseUrl,
    timeout: timeout,
    headers: headers
  });
};

export const AxiosServicePost = async (baseUrl, url, body, token) => {
  const instance = GetAxiosInstance(undefined, baseUrl, token);

  return await instance
    .post(`${url}`, body)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch(() => {
      return errorResponse;
    });
};

export const AxiosServicePostWithFile = async (baseUrl, url, header, body, token) => {
  const instance = GetAxiosInstance(header, baseUrl, token);

  return await instance
    .post(`${url}`, body)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch(() => {
      return errorResponse;
    });
};

export const AxiosServiceGet = async (baseUrl, url, params, token) => {
  const instance = GetAxiosInstance(undefined, baseUrl, token);

  return await instance
    .get(`${url}`, { params })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch(() => {
      return errorResponse;
    });
};

export const AxiosServiceUpdate = async (baseUrl, url, body, token) => {
  const instance = GetAxiosInstance(undefined, baseUrl, token);

  return await instance
    .put(`${url}`, body)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch(() => {
      return errorResponse;
    });
};

export const AxiosServiceDelete = async (baseUrl, url, token) => {
  const instance = GetAxiosInstance(undefined, baseUrl, token);

  return await instance
    .delete(`${url}`)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch(() => {
      return errorResponse;
    });
};
