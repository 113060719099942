import React, { useContext } from "react";
import { useSnackbar } from 'notistack';
import { useCurrentOrganization, useCurrentToken } from "common/hooks";
import { AppContext } from "contexts";
import { Button } from "@mui/material";
import { PrimaryButton } from "common/styles";
import { GetOrganizationByIdService , UpdateOrganizationService} from "modules/organizations/services";

export const LinkedinButtonForLogout = () => {
  const { setCurrentOrganization } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  let currentOrganization = useCurrentOrganization();
  const requestToken = useCurrentToken();

  const updateOrganization = (data) => {
    UpdateOrganizationService({
      id: data.Id,
      params: data,
      token: requestToken
    })
    .then(() => {
      enqueueSnackbar('Linkedin token eliminado con exito', { variant: `success` });
      setCurrentOrganization(data);
    })
  };

  const linkedInLogout = () => {
    GetOrganizationByIdService({
      id: currentOrganization?.Id,
      token: requestToken
    })
    .then((data) => {
      if (data !== undefined) {
        delete data.LinkedinAccessToken;
        delete data.LinkedinProfile;
        updateOrganization(data);
      }
    });
  }

  return (
      <Button style={PrimaryButton} onClick={linkedInLogout}>
        Cerrar Sesión
      </Button>
  );
}