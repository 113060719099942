export class AuthErrorHandler {
  static getErrorMessage = (error) => {
    console.log(error);
    let errorMessage;
    switch(error.code) {
      case 'NotAuthorizedException':
        errorMessage= 'Usuario o clave incorrectos';
        break;
      case 'UserNotFoundException':
        errorMessage = 'Usuario incorrecto';
        break;
      default:
        errorMessage = 'Comuniquese con el administrador del sistema';
        break;
    }

    return errorMessage;
  }
}