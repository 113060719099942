import React, { useEffect } from 'react';
import { gapi } from 'gapi-script';
import { StyledCard, SocialMediaCard, SocialMediaCardContent } from 'common/styles';
import { Grid, CardActions, Typography, Box } from '@mui/material';
import { GoogleButtonForLogin, GoogleButtonForLogout } from '../components';
import { useCurrentOrganization } from 'common/hooks';

export function YoutubeAuthorizationForm() {
  const currentOrganization = useCurrentOrganization();

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: process.env.REACT_APP_PUBLIC_GOOGLE_CLIENT_ID,
        scope: process.env.REACT_APP_YOUTUBE_AUTH_SCOPE
      });
    }
    gapi.load('client:auth2', start);
  }, []);

  return (
    <StyledCard elevation={0}>
      <Box  sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} key={'youtube-item'}>
            <SocialMediaCard key={'card-youtube'}>
              <img
                src={`${process.env.PUBLIC_URL}/img/youtube.png`}
                style={{ width: 50, height: 50, marginLeft: '42%', marginTop: 2 }}
                />
              <SocialMediaCardContent sx={{ pt: '2px', pb: '2px' }}>
                <Typography alignContent="center" alignItems="center" style={{ fontSize: 16, fontWeight: 'bold' }}>Youtube</Typography>
                {
                  currentOrganization?.YoutubeProfile
                    ? currentOrganization?.YoutubeProfile?.email
                    : <p></p>
                }
              </SocialMediaCardContent>
              <CardActions sx={{ justifyContent: 'center' }}>
              {
                currentOrganization?.YoutubeAccessToken
                  ? <GoogleButtonForLogout />
                  : <GoogleButtonForLogin />
              }
              </CardActions>
            </SocialMediaCard>
          </Grid>
        </Grid>
      </Box>
    </StyledCard>
  )
}