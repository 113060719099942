import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { CircularProgressStyle } from 'common/styles';

export function CustomCircularProgress() {

  return (
    <Box component="span" sx={CircularProgressStyle}>
      <CircularProgress />
      Cargando...
    </Box>
  );
}
