export const getChatGptQuestion = (type, socialMedia) => {
  const selectedSocialMedia = socialMedia !== undefined ? socialMedia : ''
  switch (type) {
    case chatGptPromptType.bestCopies:
      return 'genera los 5 mejores copy menos de 40 palabras:';
    case chatGptPromptType.bestTitles:
      return 'genera los 5 mejores titulos menos de 40 palabras:';
    case chatGptPromptType.bestHashtags:
      return `genera los 20 mejores hashtags para ${selectedSocialMedia} sin enumerar ni utilizar viñetas sobre:`;
    case chatGptPromptType.improveTextForSocialMedia:
      return `mejorar redacción maximo 40 palabras con voz activa, persuasiva y call to action:`;
    default:
      return 'Activo';
  }
};


export const chatGptPromptType = Object.freeze({
  bestCopies: "bestCopies",
  bestTitles: "bestTitles",
  bestHashtags: "bestHashtags",
  improveTextForSocialMedia: "improveTextForSocialMedia"
});
