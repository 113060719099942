import { AxiosServicePost } from 'common/services/AxiosBaseService';

const baseUrl = process.env.REACT_APP_CHATGPT_API_URL;
const token = process.env.REACT_APP_CHAGPT_KEY;
let apiUrl;

export const GetSuggestedTitles = async ({ question, params }) => {
  apiUrl = "engines/text-davinci-003/completions";
  const request = {
    "prompt": `${question} ${params}`,
    "temperature": 0.7,
    "max_tokens": 300
  };

  return await AxiosServicePost(baseUrl, apiUrl, request, token);
};
