import React, { useState } from 'react'
import { CalendarContext } from './CalendarContext'

export const CalendarProvider = ({ children }) => {
  const [eventDate, setEventDate] = useState()
  const [refreshCalendar, setRefreshCalendar] = useState(false);
  const [eventAction, setEventAction] = useState();
  const [currentEvent, setCurrentEvent] = useState();
  const [events, setEvents] = useState([]);
  const [eventFilter, setEventFilter] = useState();
  const [organizationMarketingCategories, setOrganizationMarketingCategories] = useState([]);

  return (
    <CalendarContext.Provider
      value={{
        eventDate,
        refreshCalendar,
        eventAction,
        currentEvent,
        events,
        eventFilter,
        organizationMarketingCategories,
        setEventDate,
        setRefreshCalendar,
        setEventAction,
        setCurrentEvent,
        setEvents,
        setEventFilter,
        setOrganizationMarketingCategories
      }}
    >
      {children}
    </CalendarContext.Provider>
  )
}
