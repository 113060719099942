import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Drawer,
  Toolbar,
  Box,
  List,
  ListItemText,
  ListItem,
  ListItemIcon,
  Collapse
} from '@mui/material';
import * as Icon from '@mui/icons-material';
import menuOptions from '../config/Menu.json';
import { AppContext } from 'contexts';
import { useCurrentOrganization, useCurrentRole } from 'common/hooks';
import { SidebarIcon, SidebarLink } from '../styles';

const LayoutSidebar = () => {
  const drawerWidth = 240;
  const [menu, setMenu] = useState([]);
  const [userRole, setUserRole] = useState();
  const [open, setOpen] = useState(false);
  const { openSidebar, setOpenSidebar } = useContext(AppContext);
  const selectedOrganization = useCurrentOrganization();
  const role = useCurrentRole();

  const handleOpen = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (role) {
      setMenu(menuOptions.menu);
      setUserRole(role);
    }
  }, [role]);

  const drawerContent = (
    <>
      <Toolbar />
      <Box sx={{ overflow: 'auto' }}>
        <List>
          {selectedOrganization &&
            menu.map(
              (item) =>
                item.permissions?.includes(userRole) && (
                  <>
                    <Link
                      to={item.route}
                      style={SidebarLink}
                      key={`link-${item.id}`}
                    >
                      <ListItem
                        button
                        key={`item-${item.name}`}
                        onClick={item.submenu?.length > 0 ? handleOpen : () => {}}
                      >
                        <ListItemIcon key={`icon-${item.icons}`} style={SidebarIcon}>
                          {React.createElement(Icon[item.icons])}
                        </ListItemIcon>
                        <ListItemText
                          key={`item-text-${item.name}`}
                          primary={item.name}
                          primaryTypographyProps={{ fontSize: 14 }}
                        />
                        {item.submenu?.length > 0 &&
                          (open
                            ? React.createElement(Icon['ExpandLess'])
                            : React.createElement(Icon['ExpandMore']))}
                      </ListItem>
                    </Link>
                    <Collapse in={open} timeout="auto" unmountOnExit key={`collapse-${item.id}`}>
                      {item.submenu?.length > 0 && (
                        <List component="div" key={`sub-list-${item.id}`} sx={{ pl: 2, pt: 0 }}>
                          {item.submenu?.map((subItem) => (
                            <Link
                              to={subItem.route}
                              style={SidebarLink}
                              key={`sublink-${subItem.id}`}
                            >
                              <ListItem button key={`sub-listItem${subItem.name}`}>
                                <ListItemIcon key={`sub-listItemIcon${item.icons}`} style={SidebarIcon}>
                                  {React.createElement(Icon[subItem.icons])}
                                </ListItemIcon>
                                <ListItemText
                                  key={`subText-${subItem.name}`}
                                  primary={subItem.name}
                                  primaryTypographyProps={{ fontSize: 14 }}
                                />
                              </ListItem>
                            </Link>
                          ))}
                        </List>
                      )}
                    </Collapse>
                  </>
                )
            )}
        </List>
      </Box>
    </>
  );

  return (
    <>
      <Drawer
        variant="temporary"
        open={openSidebar}
        onClose={() => setOpenSidebar(!openSidebar)}
        ModalProps={{
          keepMounted: true
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
            backgroundColor: '#BFDBF7'
          }
        }}
      >
        {drawerContent}
      </Drawer>
      <Drawer
        variant="permanent"
        open
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
            backgroundColor: '#BFDBF7'
          }
        }}
      >
        {drawerContent}
      </Drawer>
    </>
  );
};

export default LayoutSidebar;
