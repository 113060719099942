import React, { useContext } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { Edit, CheckCircle, RemoveCircle } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { OrganizationStatusEnum, organizationStatusLabel } from '../utils';
import { UpdateOrganizationService } from '../services';
import { useCurrentToken } from 'common/hooks';
import { useSnackbar } from 'notistack';
import { AppContext } from 'contexts';

export function useOrganizationColumns() {
  const { setRefreshGrid, refreshGrid } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const requestToken = useCurrentToken();

  const handleUpdate = (id) => {
    navigate(`/organizaciones/${id}`)
  };

  const handleChangeOrganizationStatus = (row, status) => {
    const actionMessage = status === OrganizationStatusEnum.active ? 'activada' : 'desactivada';
    row = {...row, Status: status };
    UpdateOrganizationService({
      id: row.Id,
      params: row,
      token: requestToken
    })
    .then(() => {
      enqueueSnackbar(`Organización ${actionMessage} con éxito!`, { variant: 'success' });
      setRefreshGrid(!refreshGrid);
    })
    .catch((error) => {
      enqueueSnackbar(`Se ha presentado un eror ${error}`, { variant: 'error' });
    })
  };

  const cols = [
    {
      field: 'Name',
      headerName: 'Razón Social',
      headerAlign: 'center',
      minWidth: 120,
      flex: 2
    },
    {
      field: 'Email',
      headerName: 'Correo Electrónico',
      headerAlign: 'center',
      align: 'center',
      minWidth: 120,
      flex: 2
    },
    {
      field: 'PhoneNumber',
      headerName: 'Teléfono',
      headerAlign: 'center',
      align: 'center',
      minWidth: 80,
      flex: 1
    },
    {
      field: 'Status',
      headerName: 'Status',
      headerAlign: 'center',
      valueGetter: (row) => organizationStatusLabel[row.value],
      align: 'center',
      minWidth: 80,
      flex: 1
    },
    {
      headerName: 'Acciones',
      headerAlign: 'center',
      align: 'center',
      minWidth: 120,
      field: 'action',
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="Editar">
              <IconButton onClick={() => handleUpdate(params.row.Id)}>
                <Edit fontSize="small" color="info" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Activar">
              <IconButton onClick={() => handleChangeOrganizationStatus(params.row, OrganizationStatusEnum.active)}>
                <CheckCircle fontSize="small" color="success" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Desactivar">
              <IconButton onClick={() => handleChangeOrganizationStatus(params.row, OrganizationStatusEnum.inactive)}>
                <RemoveCircle fontSize="small" color="error" />
              </IconButton>
            </Tooltip>
          </>
        );
      },
      flex: 1
    }
  ];

  return cols;
}
