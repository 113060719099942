import React, { useEffect, useContext } from 'react';
import { GoogleLogin } from 'react-google-login';
import { gapi } from 'gapi-script';
import { Button } from '@mui/material';
import { AppContext } from 'contexts';
import { useSnackbar } from 'notistack';
import { PrimaryButton } from 'common/styles';
import { gapiInit } from '../config';
import { useCurrentOrganization, useCurrentToken } from 'common/hooks';
import { GetOrganizationByIdService, UpdateOrganizationService } from 'modules/organizations/services';

export const GoogleButtonForLogin = () => {
  const { getCurrentUser, setCurrentUser, setCurrentOrganization } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const user = getCurrentUser();
  const currentOrganization = useCurrentOrganization();
  const requestToken = useCurrentToken();

  useEffect(() => {
    function start() {
      gapi.client.init(gapiInit);
    }

    gapi.load('client:auth2', start);
  }, []);

  const updateOrganization = (data) => {
    UpdateOrganizationService({
      id: data.Id,
      params: data,
      token: requestToken
    })
    .then(() => {
      enqueueSnackbar('Youtube token registrado con exito', { variant: `success` });
      setCurrentOrganization(data);
    })
  };

  const updateYoutubeToken = (youtubeData) => {
    GetOrganizationByIdService({
      id: currentOrganization?.Id,
      token: requestToken
    })
    .then((data) => {
      if (data !== undefined) {
        data.YoutubeAccessToken = youtubeData?.accessToken;
        data.YoutubeProfile = youtubeData?.profileObj;
        updateOrganization(data);
      }
    });
  };

  const onSuccess = (res) => {
    updateYoutubeToken(res);
    const youtube = {
      access_token: res?.accessToken,
      profile: res?.profileObj
    };
    setCurrentUser({ ...user, youtube });
  };

  const onFailure = (err) => {
      console.log('failed', err);
  };

  return (
    <GoogleLogin
        clientId={process.env.REACT_APP_PUBLIC_GOOGLE_CLIENT_ID}
        onSuccess={onSuccess}
        onFailure={onFailure}
        render={(renderProps) => (
          <Button
            onClick={renderProps.onClick}
            disabled={renderProps.disabled}
            style={PrimaryButton}
          >
            Autorizar
          </Button>
        )}
        cookiePolicy="single_host_origin"
        ux_mode={"popup"}
      />
  )
}