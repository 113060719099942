import { AxiosServicePost, AxiosServiceGet } from 'common/services/AxiosBaseService';

const baseUrl = process.env.REACT_APP_COVACHA_CRM;
const apiUrl = 'v1/supports';

export const GetReportsService = async ({ params, token }) => {
  return await AxiosServiceGet(baseUrl, apiUrl, params, token);
};

export const CreateReportService = async ({ params, token }) => {
  return await AxiosServicePost(baseUrl, apiUrl, params, token);
};
