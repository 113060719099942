
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { apiKey, tagsYtb } from 'common/config/youtube/ytb-apiDataV3-config';
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';

export const LabelsTrends = () => {
  const [tags, setTags] = useState([]);
  const [regionCodeSelected, setRegionCodeSelected] = useState('MX');

  useEffect(() => {
    axios.get(tagsYtb, {
      params: {
        part: 'snippet',
        chart: 'mostPopular',
        regionCode: regionCodeSelected,
        key: apiKey,
      },
    })
    .then(response => {
      let newTags = response.data.items.flatMap(item => item.snippet.tags).filter(x => x !== undefined);
      setTags(newTags);
    })
    .catch(error => {
      console.log(error);
    });
  }, [regionCodeSelected]);

  const handleGetTags = (e) => {
    setRegionCodeSelected(e.target.value);
  }

  return (
    <Grid item xs={12} sm={12} md={12}>
      <h5>Etiquetas más populares:</h5>
      <FormControl style={{ width: '100%' }}>
        <InputLabel id="country">País</InputLabel>
        <Select
          labelId="country"
          defaultValue={regionCodeSelected}
          inputProps={{ style: { fontSize: 12 }}}
          size="small"
          label="País"
          sx={{ fontSize: 12}}
          onChange={(e) => handleGetTags(e)}
          value={regionCodeSelected ?? "0"}
        >
          <MenuItem value={"0"}>Seleccione País...</MenuItem>
          <MenuItem value="AR">Argentina</MenuItem>
          <MenuItem value="BR">Brasil</MenuItem>
          <MenuItem value="CA">Canada</MenuItem>
          <MenuItem value="ES">España</MenuItem>
          <MenuItem value="MX">Mexico</MenuItem>
          <MenuItem value="US">USA</MenuItem>
          <MenuItem value="VE">Venezuela</MenuItem>
        </Select>
        <span style={{ fontSize:13 }}>{tags.slice(0, 20).map(tag => (`${tag}, `))}</span>
      </FormControl>
    </Grid>
  )
}
