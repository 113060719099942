export const awsconfig = {
  "Auth": {
    "region": process.env.REACT_APP_AMPLIFY_REGION,
    "userPoolId": process.env.REACT_APP_AMPLIFY_USER_POOL_ID,
    "userPoolWebClientId": process.env.REACT_APP_AMPLIFY_APP_CLIENT_ID,
  },
  "aws_appsync_graphqlEndpoint": process.env.REACT_APP_AMPLIFY_APP_GRAPHQL_ENDPOINT,
  "aws_appsync_region": process.env.REACT_APP_AMPLIFY_REGION,
  "aws_appsync_apiKey": process.env.REACT_APP_AMPLIFY_APP_GRAPHQL_API_KEY,
};

export const awsadminconfig = {
  "aws_project_region": process.env.REACT_APP_AMPLIFY_REGION,
  "aws_cognito_identity_pool_id": process.env.REACT_APP_AMPLIFY_USER_IDENTITY_ID,
  "region": process.env.REACT_APP_AMPLIFY_REGION,
  "aws_user_pools_id": process.env.REACT_APP_AMPLIFY_USER_POOL_ID,
  "aws_user_pools_web_client_id": process.env.REACT_APP_AMPLIFY_APP_CLIENT_ID,
  "aws_cloud_logic_custom": [
      {
          "name": process.env.REACT_APP_BACKEND_NAME,
          "endpoint": process.env.REACT_APP_BACKEND_ENDPOINT,
          "region": process.env.REACT_APP_AMPLIFY_REGION
      },
      {
          "name": process.env.REACT_APP_ADMIN_NAME,
          "endpoint": process.env.REACT_APP_ADMIN_ENDPOINT,
          "region": process.env.REACT_APP_AMPLIFY_REGION
      }
  ]
};