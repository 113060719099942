import { Auth } from 'aws-amplify';
import { AppContext } from 'contexts';
import { useContext } from 'react';

export const useCurrentRole = () => {
  const { getCurrentRole } = useContext(AppContext);
  return getCurrentRole();
};

export const isSuperAdmin = () => {
  return useCurrentRole() === 'SuperAdmin';
};

export const hasAdminPrivileges = () => {
  const adminRoles = ['Superadmin', 'Admin'];
  const userRole = useCurrentRole();
  return adminRoles.some((rol) => rol === userRole);
};

export const isOrganizationAdmin = () => {
  return useCurrentRole() === 'Full-access';
};

export const isOrganizationManager = () => {
  return useCurrentRole() === 'Manager';
};

export const isAdminOrManager = () => {
  const role = useCurrentRole();
  return role === 'Admin' || role === 'Manager';
};

export const isOrganizationSeller = () => {
  return useCurrentRole() === 'Seller';
};

export const useUserOrganizations = () => {
  const { getCurrentUser } = useContext(AppContext);
  const user = getCurrentUser();
  return user !== null ? JSON.parse(user?.info['custom:organizations']) : user;
};

export const useUserOrganizationAccessTokens = () => {
  const { getCurrentUser } = useContext(AppContext);
  const user = getCurrentUser();
  return Object.entries(user?.security_key || []).map((token) => {
    return token;
  });
};

export const useCurrentToken = () => {
  const { getCurrentToken } = useContext(AppContext);
  return getCurrentToken();
};

export const useCurrentOrganization = () => {
  const { getCurrentOrganization } = useContext(AppContext);
  return getCurrentOrganization();
};

export const useAwsGetUserProfile = async () => {
  return await Auth.currentUserInfo();
};

export const useCurrentUserInfo = () => {
  const { getCurrentUser } = useContext(AppContext);
  const { username, info } = getCurrentUser() ? getCurrentUser() : undefined;
  return {
    id: info.sub,
    name: info.name,
    family_name: info.family_name,
    email: info.email,
    userName: username,
    fullName: `${info.name}, ${info.family_name}`,
    address: info?.address?.formatted,
    identityDocument: info['custom:identity_document']
  };
}
