import React, { useEffect, useContext } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { AuthLogin } from 'modules/auth';
import { Layout, SessionLayout, OrganizationSelector } from 'common/components';
import { AppContext } from 'contexts';
import { UserProfile } from 'modules/users';
import { EventsCalendar, NewEventCalendar } from 'modules/calendar';
import { SocialMediaIntegrations } from 'modules/social-media/youtube/pages/SocialMediaIntegrations';
import { OrganizationsGrid } from 'modules/organizations/pages';
import { OrganizationForm } from 'modules/organizations/components';
import { MarketingStrategyCalendar, MarketingStrategyForm } from 'modules/marketing-strategies';
import { MarketingCategoryForm } from 'modules/marketing-categories/components';
import { MarketingCategoriesGrid } from 'modules/marketing-categories/pages';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import { SocialNetworkLinkedin } from 'modules/social-media';
export const AppRoutes = () => {
  const { getCurrentUser } = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  const user = getCurrentUser();

  useEffect(() => {
    if (user === null || user === undefined) {
      navigate('/');
    }
  }, [location?.pathname]);

  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/inicio" element={<OrganizationSelector />} />
        <Route path="/calendario" element={<EventsCalendar />} />
        <Route path="/calendario/registrar" element={<NewEventCalendar />} />
        <Route path="/calendario/:id" element={<NewEventCalendar />} />
        <Route path="/calendario-contenido" element={<MarketingStrategyCalendar />} />
        <Route path="/calendario-contenido/registrar" element={<MarketingStrategyForm/>} />
        <Route path="/calendario-contenido/:id" element={<MarketingStrategyForm />} />
        <Route path="/categorias-contenido" element={<MarketingCategoriesGrid />} />
        <Route path="/categorias-contenido/agregar" element={<MarketingCategoryForm />} />
        <Route path="/categorias-contenido/:id" element={<MarketingCategoryForm />} />
        <Route path="/usuarios/:id" element={<UserProfile />} />
        <Route path="/organizaciones" element={<OrganizationsGrid />} />
        <Route path="/organizaciones/agregar" element={<OrganizationForm />} />
        <Route path="/organizaciones/:id" element={<OrganizationForm />} />
        <Route path="/social-media" element={<SocialMediaIntegrations />} />
        <Route exact path="/linkedin" element={<LinkedInCallback />} />
        <Route path="/inlinkedin" element={<SocialNetworkLinkedin />} />
      </Route>
      <Route element={<SessionLayout />}>
        <Route path="/" element={<AuthLogin />} />
        <Route path="/login" element={<AuthLogin />} />
      </Route>
    </Routes>
  );
};