import React, { useState, useContext, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {  Divider, Grid, Typography } from '@mui/material';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { orderBy } from 'lodash';

import { PageTitle, StyledCard, StyledDivider } from 'common/styles';
import moment from 'moment';
import { AppContext } from 'contexts';
import { useCurrentOrganization, useCurrentToken } from 'common/hooks';
import { CalendarContext } from 'contexts';
import { GetMarketingContentsService, UpdateMarketingContentService } from '../services';
import { CommonFunctions, CalendarCustomFunctions, SpanishLanguage } from 'common/utils';
import { CalendarLegend } from '../components';
import { CalendarCustomEvent, CustomCircularProgress } from 'common/components';
import { useSnackbar } from 'notistack';

require('moment/locale/es.js');
const localizer = momentLocalizer(moment);
const DragAndDropCalendar = withDragAndDrop(Calendar);

export const MarketingStrategyCalendar = () => {
  const {
    refreshCalendar,
    setRefreshCalendar,
    loadingPage,
    setLoadingPage,
    setCalendarDefaultView
  } = useContext(AppContext);
  const {
    setEventDate,
    setEventAction,
    setEvents,
    setCurrentEvent,
    eventFilter,
    organizationMarketingCategories
  } = useContext(CalendarContext);
  const { enqueueSnackbar } = useSnackbar();
  const [calendarMonth, setCalendarMonth] = useState();
  const [calendarYear, setCalendarYear] = useState();
  const navigate = useNavigate();
  const requestToken = useCurrentToken();
  const currentOrganization = useCurrentOrganization();
  const [marketingContents, setMarketingContents] = useState([]);
  const organizationId = currentOrganization?.Id;

  useEffect(() => {
    setLoadingPage(true);
    const { start, end } = CommonFunctions.GetRangeDatesByMonth(calendarYear, calendarMonth);
    let params = { organization_id: organizationId };

    if (eventFilter?.length > 0) {
      params = {
        organization_id: organizationId,
        starts_at: start,
        ends_at: end,
        category_id: eventFilter
      };
    }

    GetMarketingContentsService({
      params: params,
      token: requestToken
    }).then((response) => {
      setLoadingPage(false);
      const data = orderBy(response, ['PublishAt']);
      setMarketingContents(data);
      setRefreshCalendar(false);
    });
  }, [refreshCalendar, organizationId, calendarMonth, eventFilter]);

  const CustomEvent = (event) => {
    const platforms = event.event.info.Platforms.join(', ');
    const text = `[${platforms}] ${event.event.category}`;
    const assignedTo = event?.event.assignedTo === undefined ? 'Sin responsable' : event?.event.assignedTo;
    const title = `${event.title}<br/>${assignedTo }`;
    return <CalendarCustomEvent title={title} text={text} />
  };

  const events = CalendarCustomFunctions.PreprocessMarketingContentEvent(marketingContents, organizationMarketingCategories);

  const handleChangeCalendar = (value) => {
    const monthValue = value.getMonth();
    const yearValue = value.getFullYear();

    if (monthValue !== calendarMonth) {
      setCalendarMonth(monthValue);
    }

    if (yearValue !== calendarYear) {
      setCalendarYear(yearValue);
    }
  };

  const handleSelectSlot = useCallback(({ start }) => {
      setEventDate(start);
      setEventAction('create');
      setCurrentEvent({});
      navigate('/calendario-contenido/registrar');
    }, []);

  const moveEvent = ({ event, start }) => {
    const params = {
      ...event.info,
      Id: event.info.Id,
      PublishAt: start
    };
    UpdateMarketingContentService({
      id: params.Id,
      params: params,
      token: requestToken
    })
    .then(() => {
      enqueueSnackbar('Contenido actualizado con exito!', { variant: `success` });
      setRefreshCalendar(!refreshCalendar);
    })
  };

  const fillColorEvents = (event) => {
    return { style: { background: event.categoryColor } }
  };

  const handleEditEvent = (event) => {
    setEventAction('update');
    setEvents(marketingContents);
    navigate(`/calendario-contenido/${event.id}`)
  };

  return (
    <StyledCard elevation={1}>
      <Grid container>
          <Grid item xs={12} md={6}>
            <Typography style={PageTitle}>Calendario de Contenido</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <CalendarLegend />
          </Grid>
      </Grid>
      <Divider sx={StyledDivider} />
      {loadingPage ? (
          <CustomCircularProgress />
      ): (
        <DragAndDropCalendar
          components={{ event: CustomEvent }}
          localizer={localizer}
          events={events.flat()}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 600, margin: '50px' }}
          onSelectEvent={handleEditEvent}
          onSelectSlot={handleSelectSlot}
          onEventDrop={moveEvent}
          tooltipAccessor={null}
          selectable
          views={{
            month: true,
            week: true,
            day: true
          }}
          defaultView='week'
          onView={(value) => setCalendarDefaultView(value)}
          view='week'
          messages={SpanishLanguage}
          eventPropGetter={event => fillColorEvents(event)}
          resizable
          formats={{ eventTimeRangeFormat: () => null }}
          onNavigate={(event, view, action) => {
            console.log(view)
            if (action === "DATE") {
              setCalendarDefaultView('day');
            }
            else {
              setCalendarDefaultView(view);
            }
            handleChangeCalendar(event);
          }}
        />
      )}
    </StyledCard>
  );
};
