export class AuthErrorHandler {
  static getErrorMessage = (error) => {
    let errorMessage;
    switch (error.code) {
      case 'NotAuthorizedException':
        errorMessage = 'Usuario o clave incorrectos';
        break;
      case 'UserNotFoundException':
        errorMessage = 'Usuario incorrecto. Si tienes problemas para acceder, Comuniquese con el administrador del sistema.';
        break;
      case 'UsernameExistsException':
        errorMessage = 'Ya existe un usuario registrado';
        break;
      case 'InvalidPasswordException':
        errorMessage = 'La contraseña no cumple con los requisitos de seguridad';
        break;
      case 'ExpiredCodeException':
        errorMessage = 'El código de verificación ha expirado.  Intente con un nuevo código.';
        break;
      case 'LimitExceededExceptio':
        errorMessage = 'Ha excedito el número de intento permitido.  Por favor, intente más tarde.';
        break;
      default:
        errorMessage = 'Comuniquese con el administrador del sistema';
        break;
    }

    return errorMessage;
  };
}
