import React from 'react';
import { Tooltip } from '@mui/material';
import { CalendarEventTitle } from 'common/styles';
import htmlParser from 'html-react-parser';

export const CalendarCustomEvent = ({ title, text }) => {
  return (
    <Tooltip title={htmlParser(title)}>
      <span style={CalendarEventTitle}>{text}</span>
    </Tooltip>
  );
};
