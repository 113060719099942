import React, { useState, useEffect, useContext } from 'react';
import { Divider, Grid, Typography, Box, Tooltip, Button } from '@mui/material';
import { PageTitle, StyledCard, StyledDivider, ErrorButton } from 'common/styles';
import { ChevronLeft, ChevronRight, Delete as DeleteIcon } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { CalendarContext } from 'contexts';
import { positions } from '../utils';
import { hasAdminPrivileges, useCurrentToken } from 'common/hooks';
import { ConfirmModal } from 'common/components';
import { RightSideElements, SocialTabs } from '../components';
import {  DeleteScheduleService, GetScheduleByIdService  } from '../services';

export function NewEventCalendar() {
  const { events, eventAction, currentEvent, setCurrentEvent } = useContext(CalendarContext);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const params = useParams();
  const requestToken = useCurrentToken();
  const [modalMessage, setModalMessage] = useState();
  const [confirmationModal, setConfirmationModal] = useState(false);

  const handleOpenModal = () => setConfirmationModal(true);

  const handleCloseModal = () => setConfirmationModal(false);

  const getScheduleInfo = (position) => {
    const index = events?.findIndex((event) => event.Id === currentEvent?.Id);
    const newIndex = position === 'previous' ? index - 1 : index + 1;
    let schedule;

    if ((index === 0 && position === 'next') || (index > 0))  {
      schedule = events[newIndex];
    }

    if (schedule !== undefined) {
      navigate(`/calendario/${schedule?.Id}`);
    }
  };

  const handleDeleteEvent = () => {
    DeleteScheduleService({ id: currentEvent?.Id, token: requestToken })
      .then(() => {
        handleCloseModal();
        navigate('/calendario');
        enqueueSnackbar('Post eliminado con exito!', { variant: `success` });
      })
      .catch(() => {
        enqueueSnackbar('Se ha producido un error', { variant: `error` });
      });
  };

  useEffect(() => {
    if (params?.id !== undefined) {
      GetScheduleByIdService({ id: params.id, token: requestToken })
      .then((response) => {
        setCurrentEvent(response);
      });
    }
  }, [params]);

  return (
    <StyledCard>
      <Grid container>
        <Grid item xs={8} md={8}>
          <Typography style={PageTitle}>Nueva Publicación</Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box textAlign="right">
            {eventAction === 'update' && (
            <>
              <Tooltip title="Anterior">
                <Button
                  startIcon={<ChevronLeft />}
                  size="small"
                  onClick={() => getScheduleInfo(positions.previous)}
                ></Button>
              </Tooltip>
              <Tooltip title="Siguiente">
                <Button
                  startIcon={<ChevronRight />}
                  size="small"
                  onClick={() => getScheduleInfo(positions.next)}
                ></Button>
              </Tooltip>
              {hasAdminPrivileges() && (
                <Button
                  variant="contained"
                  style={ErrorButton}
                  size="small"
                  startIcon={<DeleteIcon />}
                  onClick={() => {
                    setModalMessage('¿Confirma que desea eliminar el post?');
                    handleOpenModal();
                  }}
                >
                  Eliminar
                </Button>
              )}
            </>
            )}
          </Box>
        </Grid>
        <Divider sx={StyledDivider} />
        <Grid item xs={8} md={8}>
          <SocialTabs/>
        </Grid>
        <Grid item xs={12} sm={12} md={4} sx={{ width: '90%', pl: 3, pr: 1, background: '#FAFCFC' }}>
          <RightSideElements />
        </Grid>
      </Grid>
      <ConfirmModal
        open={confirmationModal}
        onClose={handleCloseModal}
        handleCloseModal={handleCloseModal}
        message={modalMessage}
        onConfirm={() => handleDeleteEvent()}
      />
    </StyledCard>
  );
}