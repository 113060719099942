export const s3config = {
  Auth: {
      identityPoolId: process.env.REACT_APP_AMPLIFY_USER_IDENTITY_ID,
      region: process.env.REACT_APP_AMPLIFY_REGION,
      userPoolId: process.env.REACT_APP_AMPLIFY_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_AMPLIFY_APP_CLIENT_ID
  },
  Storage: {
      bucket: 'covacha-production101306-prod',
      region: process.env.REACT_APP_AMPLIFY_REGION
  }
}