import React, { useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Amplify } from 'aws-amplify';
import {
  Grid,
  Paper,
  TextField,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  IconButton
} from '@mui/material';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import MuiPhoneNumber from 'material-ui-phone-number';
import { awsconfig } from 'common/config';
import { SubmitButton, SubmitSecondaryButton, FormActions, PageTitle, StyledDivider } from 'common/styles';
import { useSnackbar } from 'notistack';
import { AuthErrorHandler } from 'common/utils';
import { CreateOrganizationService, GetOrganizationByIdService, UpdateOrganizationService } from '../services';
import { countries } from 'common/config';
import { hasAdminPrivileges, useCurrentToken } from 'common/hooks';
import { OrganizationStatusEnum } from '../utils';
import { AppContext } from 'contexts';
import { CustomCircularProgress } from 'common/components';

Amplify.configure(awsconfig);

export function OrganizationForm() {
  const { formAction, setFormAction } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const { register, handleSubmit, formState: { errors }, clearErrors, setValue } = useForm();
  const [phone, setPhone] = useState('');
  const [countrySelected, setCountrySelect4ed] = useState('MX');
  const [showChatGptToken, setShowChatGptToken] = useState(false);
  const requestToken = useCurrentToken();
  const navigate = useNavigate();
  const params = useParams();

  const handleChangeCountry = (event) => {
    setCountrySelect4ed(event.target.value);
  };

  const handleClickChatgptButton = () => {
    setShowChatGptToken(!showChatGptToken);
  };

  const handleOnChange = (value) => {
    setPhone(value);
    if(value.length === 13) {
      clearErrors('PhoneNumber');
    }
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();
    data.PhoneNumber = phone;
    if (formAction == 'create') {
      data.Status = OrganizationStatusEnum.active;
      CreateOrganizationService({ params: data, token: requestToken })
      .then(() => {
        navigate('/organizaciones');
        enqueueSnackbar('Organización registrada con exito!', { variant: `success` });
      })
      .catch((error) => {
        enqueueSnackbar(AuthErrorHandler.getErrorMessage(error), { variant: `error` });
      });
    }
    else {
      data.Id = params?.id;
      UpdateOrganizationService({
        id: data.Id,
        params: data,
        token: requestToken
      })
      .then(() => {
        navigate('/organizaciones');
        enqueueSnackbar('Organización actualizada con exito!', { variant: `success` });
      })
      .catch((error) => {
        enqueueSnackbar(AuthErrorHandler.getErrorMessage(error), { variant: `error` });
      });
    }
  };

  useEffect(() => {
    if (params?.id === undefined) {
      setFormAction('create');
      setIsLoading(false);
      return;
    }

    GetOrganizationByIdService({ id: params?.id, token: requestToken })
    .then((data) => {
      setFormAction('update');
      setValue('Rfc', data.Rfc);
      setValue('Name', data.Name);
      setPhone(data.PhoneNumber);
      setValue('Email', data.Email);
      setValue('PostalCode', data.PostalCode);
      setValue('FiscalAddress', data.FiscalAddress);
      setValue('CountryCode', data.CountryCode);
      setValue('City', data.City);
      setValue('Status', data.Status);
      setValue('ChatgptToken', data.ChatgptToken);
      setIsLoading(false);
    })
    .catch((error) => {
      enqueueSnackbar(`Se ha producido un error. ${error}`, { variant: `error` });
    });
  }, [params]);

  return (
    <>
      { isLoading ? (
        <CustomCircularProgress />
      ):(
        <form onSubmit={handleSubmit(onSubmit)}>
          <input type="hidden" {...register('Status')} />
          <Paper elevation={2} sx={{ p: 2 }}>
            <Typography style={PageTitle}>Datos de la Organización</Typography>
            <Divider sx={StyledDivider} />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={4}>
                <TextField
                  label="RFC"
                  type="text"
                  variant="outlined"
                  color="primary"
                  margin="dense"
                  {...register('Rfc', {
                    required: { value: true, message: 'Campo Requerido' }
                  })}
                  InputLabelProps={{ shrink: true, style: { fontSize: 12 } }}
                  InputProps={{ style: { fontSize: 12 } }}
                  error={!!errors?.Rfc}
                  helperText={errors?.Rfc?.message}
                  style={{ marginLeft: '0.5%', width: '99%' }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <TextField
                  label="Razón Social"
                  type="text"
                  variant="outlined"
                  color="primary"
                  margin="dense"
                  {...register('Name', {
                    required: { value: true, message: 'Campo Requerido' }
                  })}
                  InputLabelProps={{ shrink: true, style: { fontSize: 12 } }}
                  InputProps={{ style: { fontSize: 12 } }}
                  error={!!errors?.Name}
                  helperText={errors?.Name?.message}
                  style={{ marginLeft: '0.5%', width: '99%' }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <MuiPhoneNumber
                  label="Teléfono"
                  onChange={handleOnChange}
                  defaultCountry={'mx'}
                  value={phone}
                  autoFormat={false}
                  variant="outlined"
                  InputLabelProps={{ shrink: true, style: { fontSize: 12 } }}
                  error={!!errors?.PhoneNumber?.types}
                  helperText={errors?.PhoneNumber?.types?.maxLength && errors?.phone_number?.types?.maxLength.message}
                  InputProps={{ maxLength: 13 }}
                  style={{ marginLeft: '0.5%', width: '99%' }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <TextField
                  label="Correo Electrónico"
                  type="text"
                  variant="outlined"
                  color="primary"
                  margin="dense"
                  {...register('Email', {
                    required: { value: true, message: 'Campo Requerido' }
                  })}
                  InputLabelProps={{ shrink: true, style: { fontSize: 12 } }}
                  InputProps={{ style: { fontSize: 12 } }}
                  error={!!errors?.Email}
                  helperText={errors?.Email?.message}
                  style={{ marginLeft: '0.5%', width: '99%' }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <TextField
                  label="Código Postal"
                  type="text"
                  variant="outlined"
                  color="primary"
                  margin="dense"
                  {...register('PostalCode')}
                  InputLabelProps={{ shrink: true, style: { fontSize: 12 } }}
                  InputProps={{ style: { fontSize: 12 } }}
                  error={!!errors?.PostalCode}
                  helperText={errors?.PostalCode?.message}
                  style={{ marginLeft: '0.5%', width: '99%' }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <TextField
                  label="Dirección Fiscal"
                  type="text"
                  variant="outlined"
                  color="primary"
                  margin="dense"
                  {...register('FiscalAddress', {
                    required: { value: true, message: 'Campo Requerido' }
                  })}
                  InputLabelProps={{ shrink: true, style: { fontSize: 12 } }}
                  InputProps={{ style: { fontSize: 12 } }}
                  error={!!errors?.FiscalAddress}
                  helperText={errors?.FiscalAddress?.message}
                  style={{ marginLeft: '0.5%', width: '99%' }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <TextField
                  label="Ciudad"
                  type="text"
                  variant="outlined"
                  color="primary"
                  margin="dense"
                  {...register('City', {
                    required: { value: true, message: 'Campo Requerido' }
                  })}
                  InputLabelProps={{ shrink: true, style: { fontSize: 12 } }}
                  InputProps={{ style: { fontSize: 12 } }}
                  error={!!errors?.City}
                  helperText={errors?.City?.message}
                  style={{ marginLeft: '0.5%', width: '99%' }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <FormControl style={{ marginLeft: '.5%', width: '99%' }} margin="dense">
                  <InputLabel id="country-label" sx={{ fontSize: 14 }}>País</InputLabel>
                  <Select
                    labelId="country-label"
                    {...register('CountryCode', {
                      required: { value: true, message: 'Campo Requerido' }
                    })}
                    color="primary"
                    size="small"
                    label="País"
                    value={countrySelected}
                    InputProps={{ style: { fontSize: 12 }}}
                    onChange={(e) => handleChangeCountry(e)}
                    sx={{ height: 50 }}
                  >
                    {countries.map((country) => {
                      return (
                        <MenuItem key={country.name} value={country.code} sx={{ fontSize: 12 }}>
                          {country.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              {hasAdminPrivileges() && (
                <>
                  <Grid item xs={12} sm={12} md={12} style={FormActions}>
                    <Typography style={PageTitle}>Integraciones</Typography>
                    <Divider sx={StyledDivider} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <TextField
                      label="ChatGPT Token"
                      type={showChatGptToken ? "text" : "password"}
                      variant="outlined"
                      color="primary"
                      margin="dense"
                      {...register('ChatgptToken')}
                      InputLabelProps={{ shrink: true, style: { fontSize: 12 } }}
                      InputProps={{
                        style: { fontSize: 12 },
                        endAdornment: (
                          <IconButton onClick={handleClickChatgptButton}>
                            {showChatGptToken ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        )
                      }}
                      error={!!errors?.ChatgptToken}
                      helperText={errors?.ChatgptToken?.message}
                      style={{ marginLeft: '0.5%', width: '99%' }}
                    />
                  </Grid>
                </>
              )}
            </Grid>
            <Grid item xs={12} sm={4} md={4} style={FormActions}>
              <Button variant="contained" size="small" type="submit" style={SubmitButton}>
                Guardar
              </Button>
              <Button
                variant="contained"
                size="small"
                style={SubmitSecondaryButton}
                sx={{ marginLeft: 1 }}
                onClick={() => navigate('/organizaciones')}
              >
                Cancelar
              </Button>
            </Grid>
          </Paper>
        </form>
      )}
    </>
  );
}
