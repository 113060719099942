import { Avatar, Box, Link, Typography } from '@mui/material'
import { calendarStatusAvatarLink } from 'common/styles'
import { CalendarContext } from 'contexts'
import React, { useContext } from 'react'
import { eventStatusLabel, eventStatusList } from '../utils'

export const CalendarLegend = () => {
  const { setEventFilter } = useContext(CalendarContext);

  const handleChangeEventStatus = (statusValue) => {
    setEventFilter(statusValue)
  };

  return (
    <Box display="flex"  style={{ justifyContent: 'end', flexWrap: 'wrap' }} textAlign="right">
      <Avatar key="Todos" style={calendarStatusAvatarLink} alt="T">T</Avatar>
      <Typography sx={{ fontSize: 12, fontWeight: 'bold', pl: 1, pr: 2 }}>
        <Link href='#' onClick={() => handleChangeEventStatus('')} sx={{ textDecoration: 'none' }}>Todos</Link>
      </Typography>
      {eventStatusList?.map((status) => (
        <>
          <Avatar
            key={status.name}
            style={{ height: 22, width: 22, backgroundColor: status.color, fontSize: 10 }}
            alt={status.name.charAt(0).toLocaleUpperCase()}
          >
            {status.name.charAt(0).toLocaleUpperCase()}
          </Avatar>
          <Typography sx={{ fontSize: 12, fontWeight: 'bold', pl: 1, pr: 2 }}>
            <Link href='#' onClick={() => handleChangeEventStatus(status.name)} sx={{ textDecoration: 'none' }}>{eventStatusLabel[status.name]}</Link>
          </Typography>
        </>
      ))}
    </Box>
  )
}