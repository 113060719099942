import React from 'react';
import { Chip, IconButton, Tooltip } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

export function useMarketingCategoryColumns() {
  const navigate = useNavigate();

  const handleUpdate = (id) => {
    navigate(`/categorias-contenido/${id}`)
  };

  const cols = [
    {
      field: 'Name',
      headerName: 'Nombre',
      headerAlign: 'center',
      minWidth: 120,
      flex: 2,
    },
    {
      field: 'Color',
      headerName: 'Color',
      headerAlign: 'center',
      minWidth: 80,
      align: 'center',
      renderCell: ({ row }) => {
        return (
          <Chip size="small" label={row.Color} style={{ background: row.Color, color: '#ffffff' }}/>
        );
      },
      flex: 1
    },
    {
      headerName: 'Acciones',
      headerAlign: 'center',
      align: 'center',
      minWidth: 120,
      field: 'action',
      renderCell: ({ row }) => {
        return (
          <>
            <Tooltip title="Editar">
              <IconButton onClick={() => handleUpdate(row.Id)}>
                <Edit fontSize="small" color="info" />
              </IconButton>
            </Tooltip>
          </>
        );
      },
      flex: 1
    }
  ];

  return cols;
}
