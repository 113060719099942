import {
  AxiosServiceGet,
  AxiosServiceUpdate,
  AxiosServicePost, 
  AxiosServiceDelete
} from 'common/services/AxiosBaseService';


const baseUrl = process.env.REACT_APP_COVACHA_SCHEDULE;
const apiUrl = 'v1/social-posts';

export const GetSocialPostsService = async ({ params, token }) => {
  return await AxiosServiceGet(baseUrl, apiUrl, params, token);
};

export const GetSocialPostByIdService = async ({ id, token }) => {
  return await AxiosServiceGet(baseUrl, `${apiUrl}/${id}`, null, token);
};

export const CreateSocialPostService = async ({ params, token }) => {
  return await AxiosServicePost(baseUrl, `${apiUrl}`, params, token);
};

export const DeleteSocialPostService = async ({ id, token }) => {
  return await AxiosServiceDelete(baseUrl, `${apiUrl}/${id}`, token);
};

export const UpdateSocialPostService = async ({ id, params, token }) => {
  return await AxiosServiceUpdate(baseUrl, `${apiUrl}/${id}`, params, token);
};