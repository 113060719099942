import React, { useEffect, useContext } from 'react';
import { Box, Button, Card, CardContent, Divider } from '@mui/material';
import { NoteForm } from './NoteForm';
import { NotesList } from './NotesList';
import { NotesManagerContext } from './context/NotesContext';
import { NotesSecondaryButton } from './styles';
//import { rowStatus } from 'common/utils';
import { GetNotesService } from './services/NotesServices';

export const NotesManager = ({ owner }) => {
  const { createNote, setCreateNote, setNotes, refreshNotesList } = useContext(NotesManagerContext);

  useEffect(() => {
    const params = {
      owner_type: owner?.owner_type,
      owner_id: owner?.owner_id
    };
    GetNotesService({ params: params }).then((response) => {
      setNotes(response);
    });
  }, [owner, refreshNotesList]);

  return (
    <Card style={{ marginTop: 10 }}>
      <CardContent>
        <Box display="flex" justifyContent="right">
          <Button
            size="small"
            variant="contained"
            onClick={() => setCreateNote(!createNote)}
            style={NotesSecondaryButton}
          >
            Agregar
          </Button>
        </Box>
        {createNote && <NoteForm owner={owner} />}
        <Divider sx={{ marginTop: 2 }} />
        <NotesList />
      </CardContent>
    </Card>
  );
};
