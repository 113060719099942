import React, { useContext } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  TextField,
  IconButton,
  Avatar,
  Typography,
  Divider
} from '@mui/material';
import { AppContext } from 'contexts';
import { Close } from '@mui/icons-material';
import { FormSectionTitle, StyledDivider } from 'common/styles';
import { CreateReportService } from './services';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useCurrentOrganization, useCurrentToken } from 'common/hooks';

const Support = ({ setOpen, open }) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const organizationId = useCurrentOrganization();
  const requestToken = useCurrentToken();
  const { getCurrentUser } = useContext(AppContext);
  const user = getCurrentUser();
  const onSubmit = (data) => {
    data.support.organization_id = organizationId;
    data.support.user_id = user.id;
    CreateReportService({ params: data, token: requestToken });
    toast.success('se ha generado con exito su reporte!!!');
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <FormSectionTitle variant="button" display="block" gutterBottom>
          Reporte General
        </FormSectionTitle>
        <Avatar
          src={`${process.env.PUBLIC_URL}/img/tbot.png`}
          sx={{
            width: 35,
            height: 35,
            position: 'absolute',
            right: 2,
            marginRight: '9%',
            top: 8
          }}
        />
        <Typography
          sx={{ position: 'absolute', right: 2, marginRight: '17%', marginTop: '1%', top: 8 }}
        >
          Camila Bot
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8
          }}
        >
          <Close />
        </IconButton>
        <Divider sx={StyledDivider} />
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <Paper elevation={1}>
                <TextField
                  label="Titulo del Reporte"
                  fullWidth
                  type="text"
                  variant="outlined"
                  color="primary"
                  margin="dense"
                  {...register('support.title', {
                    required: { value: true, message: 'Campo Requerido' }
                  })}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    style: { fontSize: 14 }
                  }}
                  error={!!errors?.support?.title}
                  helperText={errors?.support?.title?.message}
                  style={{ marginLeft: '1%', width: '98%' }}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Paper elevation={1} style={{ marginTop: '2%' }}>
                <TextField
                  label="Descripcion del Reporte"
                  rows={4}
                  multiline
                  type="text"
                  variant="outlined"
                  color="primary"
                  margin="dense"
                  {...register('support.description', {
                    required: { value: true, message: 'Campo Requerido' }
                  })}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    style: { fontSize: 14 }
                  }}
                  error={!!errors?.support?.description}
                  helperText={errors?.support?.description?.message}
                  style={{ marginLeft: '1%', width: '98%' }}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Button
                style={{ backgroundColor: '#357371', marginTop: '3%' }}
                type="submit"
                variant="contained"
                fullWidth
              >
                Enviar
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </form>
    </Dialog>
  );
};

export default Support;
