import React from 'react';
import { Typography } from '@mui/material';

export function CopyrightSection() {
  return (
    <Typography variant="caption" display="block" align="center" style={{ marginTop: '10%' }}>
      &copy; 2022 Todos los derechos reservados - Baat Digital
    </Typography>
  );
}
