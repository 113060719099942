import React, { useContext, useEffect } from 'react';
import { Avatar, Box, Link, Typography } from '@mui/material';
import { useCurrentOrganization, useCurrentToken } from 'common/hooks';
import { calendarStatusAvatarLink } from 'common/styles';
import { CalendarContext } from 'contexts';
import { sortBy } from 'lodash';
import { GetMarketingCategoriesService } from 'modules/marketing-categories/services';

export const CalendarLegend = () => {
  const { setEventFilter, organizationMarketingCategories, setOrganizationMarketingCategories } = useContext(CalendarContext);
  const currentOrganization = useCurrentOrganization();
  const requestToken = useCurrentToken();

  const handleChangeEventStatus = (statusValue) => {
    setEventFilter(statusValue)
  };

  useEffect(() => {
    const params = { organization_id: currentOrganization?.Id };
    GetMarketingCategoriesService({
      params: params,
      token: requestToken
    }).then((response) => {
      setOrganizationMarketingCategories(sortBy(response, ['Name', 'asc']));
    });
  }, []);

  return (
    <Box display="flex"  style={{ justifyContent: 'end', flexWrap: 'wrap' }} textAlign="right">
      <Avatar key="Todos" style={calendarStatusAvatarLink} alt="T">T</Avatar>
      <Typography sx={{ fontSize: 12, fontWeight: 'bold', pl: 1, pr: 2 }}>
        <Link href='#' onClick={() => handleChangeEventStatus('')} sx={{ textDecoration: 'none' }}>Todos</Link>
      </Typography>
      {organizationMarketingCategories?.map((category) => (
        <>
          <Avatar
            key={category.Name}
            style={{ height: 22, width: 22, fontSize: 10, backgroundColor: category.Color }}
            alt={category.Name.charAt(0).toLocaleUpperCase()}
          >
            {category.Name.charAt(0).toLocaleUpperCase()}
          </Avatar>
          <Typography sx={{ fontSize: 12, fontWeight: 'bold', pl: 1, pr: 2 }}>
            <Link href='#' onClick={() => handleChangeEventStatus(category.Id)} sx={{ textDecoration: 'none' }}>{category.Name}</Link>
          </Typography>
        </>
      ))}
    </Box>
  )
}