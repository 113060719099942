import React, { useEffect } from 'react';
import { Grid, TextField, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { FormActions, SubmitButton } from 'common/styles';
import { useSnackbar } from 'notistack';
import { AuthErrorHandler } from 'common/utils';
import { useCurrentToken, useCurrentUserInfo } from 'common/hooks';
import { UpdateUserService } from '../services/UserService';

export function PersonalInformationForm() {
  const { enqueueSnackbar } = useSnackbar();
  const user = useCurrentUserInfo();
  const requestToken = useCurrentToken();
  const { register, setValue, handleSubmit, formState: { errors } } = useForm();

  const setDefaultValues = async () => {
    setValue('identity_document', user.identityDocument);
    setValue('name', user.name);
    setValue('family_name', user.family_name);
    setValue('phone_number', user.userName);
    setValue('email', user.email);
    setValue('address', user.address);
  };

  const handleUpdateInfo = (data, e) => {
    e.preventDefault();
    const userId = user.id;
    const params = {
      username: user.userName,
      attributes: [
        { name: "custom:identity_document", value: data.identity_document },
        { name: "name", value: data.name },
        { name: "family_name", value: data.family_name },
        { name: "address", value: data.address },
        { name: "family_name", value: data.family_name }
      ]
    };
    UpdateUserService({
      id: userId,
      params: params,
      token: requestToken
    })
    .then(() => {
      enqueueSnackbar('Datos actualizados con exito!', { variant: `success` });
    })
    .catch((error) => {
      enqueueSnackbar(AuthErrorHandler.getErrorMessage(error), { variant: `error` });
    });
  };

  useEffect(() => {
    setDefaultValues();
  }, []);

  return (
    <form onSubmit={handleSubmit(handleUpdateInfo)}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4}>
          <TextField
            label="Documento de Identificación"
            type="text"
            variant="outlined"
            color="primary"
            margin="dense"
            {...register('identity_document', {
              required: { value: true, message: 'Campo Requerido' }
            })}
            InputLabelProps={{ shrink: true }}
            inputProps={{ style: { fontSize: 12 } }}
            error={!!errors?.identity_document}
            helperText={errors?.identity_document?.message}
            style={{ marginLeft: '0.5%', width: '99%' }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <TextField
            label="Nombres"
            type="text"
            variant="outlined"
            color="primary"
            margin="dense"
            {...register('name', {
              required: { value: true, message: 'Campo Requerido' }
            })}
            InputLabelProps={{ shrink: true }}
            inputProps={{ style: { fontSize: 12 } }}
            error={!!errors?.name}
            helperText={errors?.name?.message}
            style={{ marginLeft: '0.5%', width: '99%' }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <TextField
            label="Apellidos"
            type="text"
            variant="outlined"
            color="primary"
            margin="dense"
            {...register('family_name', {
              required: { value: true, message: 'Campo Requerido' }
            })}
            InputLabelProps={{ shrink: true }}
            inputProps={{ style: { fontSize: 12 } }}
            error={!!errors?.family_name}
            helperText={errors?.family_name?.message}
            style={{ marginLeft: '0.5%', width: '99%' }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <TextField
            label="Correo Electrónico"
            type="text"
            variant="outlined"
            color="primary"
            margin="dense"
            {...register('email', {
              required: { value: true, message: 'Campo Requerido' }
            })}
            InputLabelProps={{ shrink: true }}
            inputProps={{ style: { fontSize: 12 }, readOnly: true }}
            error={!!errors?.email}
            helperText={errors?.email?.message}
            style={{ marginLeft: '0.5%', width: '99%' }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <TextField
            label="Direccion"
            type="text"
            variant="outlined"
            color="primary"
            margin="dense"
            {...register('address')}
            InputLabelProps={{ shrink: true }}
            inputProps={{ style: { fontSize: 12 } }}
            error={!!errors?.address}
            helperText={errors?.address?.message}
            style={{ marginLeft: '0.5%', width: '99%' }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <TextField
            label="Ciudad"
            type="text"
            variant="outlined"
            color="primary"
            margin="dense"
            {...register('city')}
            InputLabelProps={{ shrink: true }}
            inputProps={{ style: { fontSize: 12 } }}
            error={!!errors?.city}
            helperText={errors?.city?.message}
            style={{ marginLeft: '0.5%', width: '99%' }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <TextField
            label="Estado"
            type="text"
            variant="outlined"
            color="primary"
            margin="dense"
            {...register('state')}
            InputLabelProps={{ shrink: true }}
            inputProps={{ style: { fontSize: 12 } }}
            error={!!errors?.state}
            helperText={errors?.state?.message}
            style={{ marginLeft: '0.5%', width: '99%' }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <TextField
            label="Telefono"
            type="text"
            variant="outlined"
            color="primary"
            margin="dense"
            {...register('username')}
            InputLabelProps={{ shrink: true }}
            inputProps={{
              style: { fontSize: 14 },
              readOnly: true
            }}
            style={{ marginLeft: '1%', width: '98%' }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <TextField
            label="Usuario"
            type="text"
            variant="outlined"
            color="primary"
            margin="dense"
            {...register('username')}
            InputLabelProps={{ shrink: true }}
            inputProps={{
              style: { fontSize: 14 },
              readOnly: true
            }}
            style={{ marginLeft: '1%', width: '98%' }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sm={4} md={4} style={FormActions}>
        <Button type="submit" variant="contained" size="small" style={SubmitButton}>
          Guardar
        </Button>
      </Grid>
    </form>
  );
}
