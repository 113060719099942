import {
  AxiosServiceGet,
  AxiosServiceUpdate,
  AxiosServicePost, 
  AxiosServiceDelete
} from 'common/services/AxiosBaseService';

const baseUrl = process.env.REACT_APP_COVACHA_CORE;
const apiUrl = 'v1/organizations';

export const GetOrganizationsService = async ({ params, token }) => {
  return await AxiosServiceGet(baseUrl, apiUrl, params, token);
};

export const GetOrganizationByIdService = async ({ id, token }) => {
  return await AxiosServiceGet(baseUrl, `${apiUrl}/${id}`, null, token);
};

export const CreateOrganizationService = async ({ params, token }) => {
  return await AxiosServicePost(baseUrl, `${apiUrl}`, params, token);
};

export const DeleteOrganizationService = async ({ id, token }) => {
  return await AxiosServiceDelete(baseUrl, `${apiUrl}/${id}`, token);
};

export const UpdateOrganizationService = async ({ id, params, token }) => {
  return await AxiosServiceUpdate(baseUrl, `${apiUrl}/${id}`, params, token);
};