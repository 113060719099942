import React from 'react';
import { StyledCard } from 'common/styles';
import { Grid, Typography, Box } from '@mui/material';
import { YoutubeForm } from '../components';
import { useCurrentOrganization } from 'common/hooks';

export function SocialMediaIntegrations({video}) {
  const currentOrganization = useCurrentOrganization();

  return (
    <StyledCard elevation={0}>
      <Box  sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          {currentOrganization?.YoutubeAccessToken === undefined ?
          (
            <strong><i>Necesita autorizar la integración, consulte con el manager de la organización...</i></strong>
          )
          :
          (
            <>
              <Grid item sm={12}>
                <Typography sx={{ fontWeight: 'bold', fontSize: 12, marginBottom: 2 }} textAlign="right">
                  Cuenta integrada: {currentOrganization?.YoutubeProfile?.email}
                </Typography>
              </Grid>
              <Grid item sm={12}>
                <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: 16, marginBottom: 2 }}>
                  Datos de la Publicación
                </Typography>
              </Grid>
              <Grid container spacing={2}>
                <Grid item sx={12} sm={12} md={10} style={{ marginTop: 5 }}>
                  <Box  sx={{ flexGrow: 1 }}>
                    <YoutubeForm video={video} />
                  </Box>
                </Grid>
              </Grid>
           </>
          )}
        </Grid>
      </Box>
    </StyledCard>
  )
}