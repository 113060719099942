import React from 'react';
import { Box } from '@mui/system';
import { Outlet } from 'react-router-dom';

export const SessionLayout = ({ children }) => {
  return (
    <Box sx={{ display: 'flex', columnGap: '2%' }}>
      <Outlet>{children}</Outlet>
    </Box>
  );
};
