import {
  AxiosServiceGet,
  AxiosServiceUpdate,
  AxiosServicePost, 
  AxiosServiceDelete
} from 'common/services/AxiosBaseService';

const baseUrl = process.env.REACT_APP_COVACHA_SCHEDULE;
const apiUrl = 'v1/marketing-categories';

export const GetMarketingCategoriesService = async ({ params, token }) => {
  return await AxiosServiceGet(baseUrl, apiUrl, params, token);
};

export const GetMarketingCategoryByIdService = async ({ id, token }) => {
  return await AxiosServiceGet(baseUrl, `${apiUrl}/${id}`, null, token);
};

export const CreateMarketingCategoryService = async ({ params, token }) => {
  return await AxiosServicePost(baseUrl, `${apiUrl}`, params, token);
};

export const DeleteMarketingCategoryService = async ({ id, token }) => {
  return await AxiosServiceDelete(baseUrl, `${apiUrl}/${id}`, token);
};

export const UpdateMarketingCategoryService = async ({ id, params, token }) => {
  return await AxiosServiceUpdate(baseUrl, `${apiUrl}/${id}`, params, token);
};