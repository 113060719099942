import React, { useContext } from 'react';
import { GoogleLogout } from 'react-google-login';
import { Button } from '@mui/material';
import { AppContext } from 'contexts';
import { useSnackbar } from 'notistack';
import { PrimaryButton } from 'common/styles';
import { useCurrentOrganization, useCurrentToken } from 'common/hooks';
import { GetOrganizationByIdService, UpdateOrganizationService } from 'modules/organizations/services';

export const GoogleButtonForLogout = () => {
  const { getCurrentUser, setCurrentUser, setCurrentOrganization } = useContext(AppContext);
  const currentOrganization = useCurrentOrganization();
  const { enqueueSnackbar } = useSnackbar();
  const requestToken = useCurrentToken();
  const user = getCurrentUser();

  const updateOrganization = (data) => {
    UpdateOrganizationService({
      id: data.Id,
      params: data,
      token: requestToken
    })
    .then((response) => {
      setCurrentOrganization(response)
      enqueueSnackbar('Youtube token eliminado con exito', { variant: `success` });
    })
  };

  const removeYoutubeToken = () => {
    GetOrganizationByIdService({
      id: currentOrganization?.Id,
      token: requestToken
    })
    .then((data) => {
      if (data !== undefined) {
        delete data.YoutubeAccessToken;
        delete data.YoutubeProfile;
        updateOrganization(data);
      }
    });
  };

  const logout = () => {
    delete user.youtube;
    removeYoutubeToken();
    setCurrentUser({ ... user });
  };

  return (
    <GoogleLogout
      clientId={process.env.REACT_APP_PUBLIC_GOOGLE_CLIENT_ID}
      buttonText="Logout"
      onLogoutSuccess={logout}
      render={(renderProps) => (
        <Button
          onClick={renderProps.onClick}
          disabled={renderProps.disabled}
          style={PrimaryButton}
        >
          Cerrar Sesión
        </Button>
      )}
    />
  );
}