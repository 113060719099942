import React, { useState } from 'react';
import { Paper, Box, Tab } from '@mui/material';
import { Amplify } from 'aws-amplify';
import { AutoAwesomeMosaic, PermContactCalendar, Lock } from '@mui/icons-material';
import { TabPanel, TabContext, TabList } from '@mui/lab';
import { awsconfig } from 'common/config';
import { hasAdminPrivileges } from 'common/hooks';
import { IntegrationsForm, PersonalInformationForm, UserSecurityForm } from '../components';

Amplify.configure(awsconfig);

export function UserProfile() {
  const [value, setValue] = useState('3');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Paper sx={{ px: 2, height: '100%', overflow: 'auto' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange}>
            <Tab
              label="Información Personal"
              value="1"
              icon={<PermContactCalendar fontSize="small" />}
              iconPosition="start"
              sx={{ fontSize: 12 }}
            />
            <Tab
              label="Seguridad"
              value="2"
              icon={<Lock fontSize="small" />}
              iconPosition="start"
              sx={{ fontSize: 12 }}
            />
            {hasAdminPrivileges() && (
              <Tab
                label="Integraciones"
                value="3"
                icon={<AutoAwesomeMosaic fontSize="small" />}
                iconPosition="start"
                sx={{ fontSize: 12 }}
              />
            )}
          </TabList>
        </Box>
        <TabPanel value="1"><PersonalInformationForm /></TabPanel>
        <TabPanel value="2"><UserSecurityForm /></TabPanel>
        <TabPanel value="3"><IntegrationsForm /></TabPanel>
      </TabContext>
    </Paper>
  );
}