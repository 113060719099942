import React,{ useContext, useEffect, useState } from 'react';
import { Button, FormControl, Grid, TextField } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { FormInputSize, FormInputStyle, PrimaryButton, StyleDragArea, FormLabel } from 'common/styles';
import { CalendarContext } from 'contexts';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import axios from 'axios';

export const VideoPost = ({post, video}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { register, handleSubmit, formState: { errors }, setValue  } = useForm();
  const { eventDate, } = useContext(CalendarContext);
  const [uploadVideo, setUploadVideo] = useState(null);
  const urlVideo = `${process.env.REACT_APP_BUCKET_URL}${video}`;
  const [videoDetails, setVideoDetails] = useState({
    Title:'',
    Description:'',
    file: null
  });
  const [file, setFile] = useState(null);

  useEffect(() => {
    setValue('Title', post.Title);
    setValue('Description', post.Description);    
  }, [])
  useEffect(() => {
    setUploadVideo(urlVideo)
  },[urlVideo])
  const onSubmit = async (data, e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('video', file);

    console.log("Enviando......");
    axios.post('https://4ftvx4y6ba.execute-api.us-east-1.amazonaws.com/production/post-image', formData,)
    .then((response) => {
      console.log(response);
      if(response.statusCode === 200){
        enqueueSnackbar('Post publicado con exito!', { variant: `success` });
      }
    }, (error) => {
      enqueueSnackbar('Se ha producido un error', { variant: `error` });
      console.log(error)
    });
    return false;
  };

  const handleChangeVideo = (e) => {
    setFile(e.target.files[0]);
    const inputValue = e.target.name === "file" ? e.target.files[0] : e.target.value;
    setVideoDetails({
      ...videoDetails,
      [e.target.name] : inputValue
    });
    const reader = new FileReader();
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      reader.readAsDataURL(selectedFile);
    }
    reader.onload = (readerEvent) => {
      if (selectedFile.type.includes("video")) {
        setUploadVideo(readerEvent.target.result);
      }
    };
  }
  return (
    <Grid container>
        <Grid item xs={12} sm={12} md={12} style={{ marginTop: 25 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <FormControl style={{ width: '100%' }}>
                      <DateTimePicker
                        fullWidth
                        label="Fecha Publicación"
                        value={eventDate || null}
                        style={FormInputStyle}
                        // onChange={handleChangeEventDate}
                        disablePast
                        inputProps={FormInputSize}
                        renderInput={(params) => (
                          <TextField {...params} InputLabelProps={{ shrink: true }} size="small" />
                        )}
                      />
                    </FormControl>
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <FormControl style={{ width: '100%' }} margin="dense">
                    <TextField
                      {...register('Title', {
                        minLength: { value: 2, message: 'Debe ingresar el titulo del video.' }
                      })}
                      id="outlined-multiline-flexible"
                      label="Título"
                      labelId="format-label"
                      error={!!errors?.Title}
                      fullWidth
                      style={FormInputStyle}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ style: { fontSize: 12 }}}
                      type="text"
                      sx={{fontSize:12}}
                      color="primary"
                      size='meddium'
                      // onChange={handleChange}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <FormControl style={{ width: '100%' }} margin="dense">
                    <TextField
                      {...register('Description', {
                        minLength: { value: 2, message: 'Debe ingresar la descripción del post.' }
                      })}
                      id="outlined-multiline-flexible"
                      label="Descripción"
                      multiline
                      rows={10}
                      error={!!errors?.Description}
                      fullWidth
                      style={FormInputStyle}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        style: { fontSize: 12 },
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <StyleDragArea>
                    <div className='image-upload-wrap'>
                      <input type="file" name="file" onChange={handleChangeVideo} accept='video/*' className='file-upload-input'/>
                      <div className="text-information">
                        <h4 style={FormLabel}>Seleccione el Video</h4>
                      </div>
                    </div>
                  </StyleDragArea>
                </Grid>
                <Grid item xs={12} sm={12} md={12} style={{ marginTop: 15 }}>
                  {videoDetails.file != null && <video controls width={550} height="230" src={uploadVideo}></video>}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6} sx={{ marginTop: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <Button variant="contained" type="submit" style={PrimaryButton}>Enviar</Button>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  )
}

