export class Format {
  static Money(value) {
    return Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
  }

  static PhoneNumber(phone) {
    if (phone === undefined) {
      return '';
    }

    var a = phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    return !a[2] ? a[1] : '' + a[1] + '-' + a[2] + (a[3] ? '-' + a[3] : '');
  }

  static DateTime(value) {
    if (value === undefined) {
      return '';
    }

    const d = new Date(value);
    return (
      [('0' + d.getDate()).slice(-2), ('0' + (d.getMonth() + 1)).slice(-2), d.getFullYear()].join(
        '/'
      ) +
      ' ' +
      [
        d.getHours() % 12 || 12,
        ('0' + d.getMinutes()).slice(-2),
        ('0' + d.getSeconds()).slice(-2)
      ].join(':') +
      ' ' +
      [d.getHours() > 12 ? 'p.m.' : 'a.m.']
    );
  }

  static Date(value) {
    if (value === undefined) {
      return '';
    }

    const d = new Date(value);
    return [
      ('0' + d.getDate()).slice(-2),
      ('0' + (d.getMonth() + 1)).slice(-2),
      d.getFullYear()
    ].join('/');
  }

  static USDate(value) {
    if (value === undefined) {
      return '';
    }

    const d = new Date(value);
    return [
      d.getFullYear(),
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2)
    ].join('-');
  }

  static USDateTime(value) {
    if (value === undefined) {
      return '';
    }

    const d = new Date(value);
    return (
      [d.getFullYear(), ('0' + (d.getMonth() + 1)).slice(-2), ('0' + d.getDate()).slice(-2)].join(
        '-'
      ) +
      'T' +
      [
        ('0' + (d.getHours() % 12) || 12).slice(-2),
        ('0' + d.getMinutes()).slice(-2),
        ('0' + d.getSeconds()).slice(-2)
      ].join(':')
    );
  }
}
