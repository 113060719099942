import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { Amplify } from 'aws-amplify';
import { useForm } from 'react-hook-form';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useDropzone } from 'react-dropzone';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { sortBy } from 'lodash';
import { CalendarContext } from 'contexts';
import { Tag as TagIcon, Assignment as ListIcon, DoneAll as ImproveIcon } from '@mui/icons-material';
import { FormLabel, FormInputStyle, PrimaryButton, SecondaryButton, FormInputSize } from 'common/styles';
import postTypes from '../json/PostType.json';
import postStatus from '../json/PostStatus.json';
import { CreateScheduleService, GetScheduleByIdService, UpdateScheduleService } from '../services';
import { useCurrentOrganization, useCurrentToken } from 'common/hooks';
import { GetMarketingCategoriesService } from 'modules/marketing-categories/services';
import { socialMediaList } from '../utils/SocialMediaLabel';
import { s3config } from 'common/config';
import { GetSuggestedTitles } from 'modules/ai-tools/chatgpt';
import { CustomLinearProgress, CustomList } from 'common/components';
import { CommonFunctions } from 'common/utils';
import { chatGptPromptType } from 'common/utils';
import { getChatGptQuestion } from 'common/utils';

export const CalendarForm = () => {
  Amplify.configure(s3config);
  const { eventDate, eventAction, currentEvent, setCurrentEvent, setEventDate } = useContext(CalendarContext);
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const requestToken = useCurrentToken();
  const currentOrganization = useCurrentOrganization();
  const [postType, setPostType] = useState(0);
  const [platforms, setPlatforms] = useState([]);
  const [ setShowFilesList] = useState(false);
  const [eventStatus, setEventStatus] = useState('pending');
  const [marketingCategory, setMarketingCategory] = useState(0);
  const [copyErrorMessage, setCopyErrorMessage]  = useState(false);
  const [platformsErrorMessage, setPlatformsErrorMessage] = useState(false);
  const [marketingCategories, setMarketingCategories] = useState([]);
  const [hasTitleSuggestions, setHasTitleSuggestions] = useState(false);
  const [searchingTitles, setSearchingTitles] = useState(false);
  const [hasDescriptionSuggestions, setHasDescriptionSuggestions] = useState(false);
  const [searchingDescripcionChoices, setSearchingDescriptionChoices] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [descriptionSuggestions, setDescriptionSuggestions] = useState([]);
  const { register, handleSubmit, formState: { errors }, clearErrors, getValues, setValue } = useForm();
  const { acceptedFiles } = useDropzone();
  let organizationId = currentOrganization?.Id;

  const handleInputChange = (fieldName, value) => {
    const newText = /^\d/.test(value) ? value.slice(3) : value;
    setValue(fieldName, newText);
    fieldName === "Title" ? setHasTitleSuggestions(false) : setHasDescriptionSuggestions(false);
  };

  const askChatgptforSuggestions = (fieldName, questionFlag) => {
    const formValues = getValues();
    fieldName === "Title" ? setSearchingTitles(true) : setSearchingDescriptionChoices(true);
    GetSuggestedTitles({
      question: getChatGptQuestion(questionFlag),
      params: formValues[fieldName]
    })
    .then((response) => {
      const options = CommonFunctions.ProcessChatGptResponse(response);
      if (fieldName === "Title") {
        setHasTitleSuggestions(true);
        setSearchingTitles(false);
        setSuggestions(options);
      }
      else {
        setHasDescriptionSuggestions(true);
        setSearchingDescriptionChoices(false);
        setDescriptionSuggestions(options)
      }
    })
    .catch(() => {
      console.log('Se ha presentado un error');
    })
  };

  const handleChangePostType = (event) => {
    setPostType(event.target.value);
    if (event.target.value !== '0') {
      clearErrors('Type');
    }
  };

  const handleChangeMarketingCategory = (event) => {
    setMarketingCategory(event.target.value);
    if (event.target.value !== '0') {
      clearErrors('MarketingCategory');
    }
  };

  const handleChangeEventDate = (newValue) => setEventDate(newValue);

  const handlePlatforms = (event) => {
    if (event.target.checked) {
      setPlatforms([...platforms, event.target.value]);
    }
    else {
      const data = platforms.filter((p) => p !== event.target.value);
      setPlatforms(data);
    }
    if (platforms?.length > 0) {
      setPlatformsErrorMessage(false);
    }
  };

  const handleChangeEventStatus = (event, value) => {
    if (event.target.checked)
    {
      setEventStatus(value);
    }
  };

  useEffect(() => {
    if (acceptedFiles?.length > 0) {
      setShowFilesList(true);
    }
  }, [acceptedFiles]);

  const fetchMarketingCategories = () => {
    const params = { organization_id: organizationId };
    GetMarketingCategoriesService({
      params: params,
      token: requestToken
    }).then((response) => {
      setMarketingCategories(sortBy(response, ['Name', 'asc']));
    });
  };

  useEffect(() => {
    organizationId = currentOrganization?.Id;
    fetchMarketingCategories();
  }, []);

  useEffect(() => {
    organizationId = currentOrganization?.Id;
    fetchMarketingCategories();
  }, [organizationId]);

  useEffect(() => {
    if (params?.id !== undefined) {
      GetScheduleByIdService({ id: params.id }).then((response) => {
        setCurrentEvent(response);
        setEventDate(response.PublishAt);
        setPostType(response.Type);
        setMarketingCategory(response.CategoryId);
        setPlatforms([...response.Platforms]);
        setEventStatus(response.EventStatus);
        setValue('Copy', response.Copy);
        setValue('Description', response.Description);
        setValue('Title', response.Title);
        setValue('Type', response.Type);
        setValue('CategoryId', response.CategoryId);
        setValue('Status', response.Status);
        setValue('Designer', response.Designer);
        setValue('CommunityManager', response.CommunityManager);
      });
    }
  }, [params]);

  const checkFormErrors = (data) => {
    let isOk = true;

    if (platforms?.length < 1) {
      setPlatformsErrorMessage(true);
      isOk = false;
    }
    if (data?.Copy === undefined || data?.Copy === '') {
      setCopyErrorMessage(true);
      isOk = false;
    }
    return isOk;
  }

  const onSubmit = async (data, e) => {
    e.preventDefault();

    if (!checkFormErrors(data)) {
      return;
    }

    data.OrganizationId = organizationId;
    data.EventStatus = eventStatus;
    data.Status = eventStatus;
    data.PublishAt =  eventDate;
    data.Platforms = platforms;
    data.CategoryId =  marketingCategory;

    if (eventAction === 'create') {
      CreateScheduleService({ params: data, token: requestToken })
      .then(() => {
        navigate('/calendario');
        enqueueSnackbar('Post registrado con exito!', { variant: `success` });
      })
      .catch(() => {
        enqueueSnackbar('Se ha producido un error', { variant: `error` });
      });
    }
    else {
      data.Id = currentEvent?.Id;
      UpdateScheduleService({
        id: currentEvent?.Id,
        params: data,
        token: requestToken
      })
      .then(() => {
        navigate('/calendario');
        enqueueSnackbar('Post actualizado con exito!', { variant: `success` });
      })
      .catch(() => {
        enqueueSnackbar('Se ha producido un error', { variant: `error` });
      });
    }
  };

  return (
      <Grid container spacing={3} sx={{ marginTop: 0.5 }}>
        <Grid item xs={12} sm={12} md={12} sx={{ pr: 2 }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <Typography style={FormLabel}>Seleccione las redes sociales:</Typography>
                {platformsErrorMessage && (
                    <FormHelperText id="format-helper-text" error>Debe seleccionar al menos una red social</FormHelperText>
                  )}
                {socialMediaList.map((sm) => (
                  <FormControlLabel
                    key={`label-${sm.name}`}
                    control={
                      <Checkbox
                        label={sm.name}
                        value={sm.name}
                        key={sm.name}
                        onClick={handlePlatforms}
                        checked={platforms.filter((p) => p === sm.name ).length > 0}
                      />
                    }
                    labelPlacement="start"
                    label={
                      <Tooltip title={sm.name}>
                        <img src={`${process.env.PUBLIC_URL}/img/${sm.name}.png`} height="24" alt={sm.name} />
                      </Tooltip>
                    }
                  />
                ))}
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <FormControl style={{ width: '100%' }}>
                    <DateTimePicker
                      fullWidth
                      label="Fecha Publicación"
                      value={eventDate || null}
                      style={FormInputStyle}
                      onChange={handleChangeEventDate}
                      disablePast
                      inputProps={FormInputSize}
                      renderInput={(params) => (
                        <TextField {...params} InputLabelProps={{ shrink: true }} size="small" />
                      )}
                    />
                  </FormControl>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <FormControl style={FormInputStyle}>
                  <InputLabel id="format-label" sx={{ fontSize: 14 }}>Formato</InputLabel>
                  <Select
                    labelId="format-label"
                    {...register('Type', {
                      minLength: { value: 2, message: 'Debe seleccionar un formato' }
                    })}
                    inputProps={FormInputSize}
                    onChange={(e) => handleChangePostType(e)}
                    color="primary"
                    size="small"
                    value={postType}
                    sx={{ fontSize: 12 }}
                    label="Formato"
                    error={!!errors?.Type}
                  >
                    <MenuItem value={0} sx={{ fontSize: 12 }}>Seleccione...</MenuItem>
                    {postTypes?.type.map((format) => {
                      return (
                        <MenuItem key={format.name} value={format.id} sx={{ fontSize: 12 }}>
                          {format.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {errors?.Type && (
                    <FormHelperText id="format-helper-text" error>
                      {errors?.Type?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <FormControl margin="none" style={{ width: '100%' }} >
                  <InputLabel id="format-label" sx={{ fontSize: 14 }}>Categoria</InputLabel>
                  <Select
                    labelId="format-label"
                    {...register('CategoryId', {
                      minLength: { value: 2, message: 'Debe seleccionar un formato' }
                    })}
                    inputProps={FormInputSize}
                    onChange={(e) => handleChangeMarketingCategory(e)}
                    color="primary"
                    size="small"
                    value={marketingCategory}
                    sx={{ fontSize: 12 }}
                    label="Categoria"
                    error={!!errors?.Type}
                  >
                    <MenuItem value={0} sx={{ fontSize: 12 }}>Seleccione...</MenuItem>
                    {marketingCategories?.map((category) => {
                      return (
                        <MenuItem key={category.Name} value={category.Id} sx={{ fontSize: 12 }}>
                          {category.Name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {errors?.CategoryId && (
                    <FormHelperText id="format-helper-text" error>
                      {errors?.CategoryId?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Paper elevation={0}>
                  <Typography variant='caption' style={FormLabel}>Copy:</Typography>
                  <CKEditor
                    {...register('Copy', {
                      minLength: { value: 2, message: 'Debe ingresar el texto del copy' }
                    })}
                    editor={ClassicEditor}
                    data={currentEvent?.Copy || ''}
                    onChange={(event, editor) => {
                      setValue('Copy', editor.getData())
                      setCopyErrorMessage(false);
                    }}
                    config={{ autoParagraph: false }}
                    onReady={(editor) => {
                      editor.editing.view.change((writer) => {
                        writer.setStyle(
                            "height",
                            "300px",
                            editor.editing.view.document.getRoot()
                        );
                      });
                    }}
                  />
                  {copyErrorMessage && (
                    <FormHelperText id="format-helper-text" error>Debe ingresar el texto del copy</FormHelperText>
                  )}
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <FormControl style={{ width: '100%' }} margin="dense">
                  <TextField
                    {...register('Title', {
                      minLength: { value: 2, message: 'Debe ingresar la descripción del post.' }
                    })}
                    id="outlined-multiline-flexible"
                    label="Título"
                    multiline
                    rows={2}
                    error={!!errors?.Title}
                    fullWidth
                    style={FormInputStyle}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      style: { fontSize: 12 },
                      endAdornment: (
                        <>
                          <Tooltip title="Títulos Sugeridos">
                            <IconButton>
                              <ListIcon fontSize='small' onClick={() => askChatgptforSuggestions('Title', chatGptPromptType.bestTitles)}/>
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Mejorar título">
                            <IconButton>
                              <ImproveIcon fontSize='small' onClick={() => askChatgptforSuggestions('Title', chatGptPromptType.improveTextForSocialMedia)}/>
                            </IconButton>
                          </Tooltip>
                        </>
                        )
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                {searchingTitles && <CustomLinearProgress text="Buscando sugerencias..." />}
                {hasTitleSuggestions && (
                  <CustomList
                    title="Resultados:"
                    elements={suggestions}
                    name="Title"
                    onClick={handleInputChange}
                    showList={setHasTitleSuggestions}
                  />
                  )}
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <FormControl style={{ width: '100%' }} margin="dense">
                  <TextField
                    {...register('Description', {
                      minLength: { value: 2, message: 'Debe ingresar la descripción del post.' }
                    })}
                    id="outlined-multiline-flexible"
                    label="Descripción"
                    multiline
                    rows={10}
                    error={!!errors?.Description}
                    fullWidth
                    style={FormInputStyle}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      style: { fontSize: 12 },
                      endAdornment: (
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                          <Tooltip title="Sugerencias Titulos">
                            <IconButton>
                              <ListIcon  fontSize='small' onClick={() => askChatgptforSuggestions('Description', chatGptPromptType.bestCopies)} />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Sugerencias Etiquetas">
                            <IconButton>
                              <TagIcon fontSize='small' onClick={() => askChatgptforSuggestions('Description', chatGptPromptType.bestHashtags)}/>
                            </IconButton>
                          </Tooltip>
                        </div>
                      )
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                {searchingDescripcionChoices && <CustomLinearProgress text="Buscando sugerencias..." />}
                {hasDescriptionSuggestions && (
                  <CustomList
                    title="Resultados:"
                    elements={descriptionSuggestions}
                    name="Description"
                    onClick={handleInputChange}
                    showList={setHasDescriptionSuggestions}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <FormControl style={{ width: '100%' }} margin="dense">
                  <TextField
                    {...register('Designer')}
                    id="outlined-multiline-flexible"
                    label="Diseñador"
                    error={!!errors?.Designer}
                    fullWidth
                    style={FormInputStyle}
                    InputLabelProps={{ shrink: true }}
                    inputProps={FormInputSize}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <FormControl style={{ width: '100%' }} margin="dense">
                  <TextField
                    {...register('CommunityManager')}
                    id="outlined-multiline-flexible"
                    label="Community Manager"
                    error={!!errors?.CommunityManager}
                    fullWidth
                    style={FormInputStyle}
                    InputLabelProps={{ shrink: true }}
                    inputProps={FormInputSize}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                {postStatus?.status?.map((status) => {
                  return <FormControlLabel
                    key={`${status.key}-label`}
                    control={
                      <Checkbox
                        label={status.label}
                        value={status.key}
                        key={status.key}
                        onClick={(e) => handleChangeEventStatus(e, status.key)}
                        checked={eventStatus === status.key}
                      />
                    }
                    label={status.label}
                  />
                })}
              </Grid>
              <Grid item xs={12} sm={12} md={6} sx={{ marginTop: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <Button variant="contained" type="submit" style={PrimaryButton}>Guardar</Button>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Button variant="contained" style={SecondaryButton} onClick={() => navigate('/calendario')}>Cancelar</Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
  );
}
