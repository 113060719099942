import React from 'react';
import { Layout, SessionLayout } from 'common/components';
import { Toaster } from 'react-hot-toast';
import { AppProvider } from 'contexts';
import { AppRoutes } from 'common/config/AppRoutes';

function App() {
  return (
    <AppProvider>
      <Toaster position="top-right" reverseOrder={false} />
      <AppRoutes>
        <Layout />
        <SessionLayout />
      </AppRoutes>
    </AppProvider>
  );
}

export default App;
