import React from 'react';
import { Grid, Box, Typography } from '@mui/material';
import { YoutubeAuthorizationForm, LinkedinAuthorizationForm } from 'modules/social-media';

export const IntegrationsForm = () => {

  return (
    <Box  sx={{ flexGrow: 1 }}>
      <Grid container spacing={1}>
        <Grid item sm={12}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: 16, marginBottom: 2 }}>
            Integraciones Disponibles:
          </Typography>
        </Grid>
        <Grid item sx={12} md={3}>
          <YoutubeAuthorizationForm />
        </Grid>
        <Grid item sx={12} md={3}>
          <LinkedinAuthorizationForm />
        </Grid>
      </Grid>
    </Box>
  );
};
