import React from 'react';
import { Grid, TextField, Divider, Button } from '@mui/material';
import { FormSectionTitle, PageTitle, PrimaryButton, StyledDivider } from 'common/styles';
import { useForm } from 'react-hook-form';
import { useCurrentToken, useCurrentUserInfo } from 'common/hooks';
import { useSnackbar } from 'notistack';
import { UpdateUserPasswordService } from '../services/UserService';

export const UserSecurityForm = () => {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    clearErrors,
    setError,
    formState: { errors }
  } = useForm();
  const { enqueueSnackbar } = useSnackbar()
  const user = useCurrentUserInfo();
  const requestToken = useCurrentToken();

  const setFormDefaultValue = () => {
    setValue('password', '');
    setValue('password_confirmation', '');

  };

  const handleChangePassword = async (data, e) => {
    e.preventDefault();
    const params = {
      username: user.userName,
      password : data?.password
    };
    const userId = user.id;
    UpdateUserPasswordService({ userId, params, requestToken})
    .then((response) => {
        if (response.statusCode === 200) {
          setFormDefaultValue();
          enqueueSnackbar('Clave de usuario actualizada con exito.', { variant: `success` });
        }
    })
    .catch(() => {
      enqueueSnackbar('Se ha producido un error.', { variant: `error` });
    });
  };

  const handleChangePasswordConfirmation = (event) => {
    const data = getValues();
    const confirmation = event.target.value;
    if (data?.password !== confirmation) {
      setError('password_confirmation', {
        type: 'manual',
        message: 'La contrasena y la confirmacion no coinciden'
      });
    } else {
      clearErrors('password_confirmation');
    }
  };

  return (
    <>
      <Grid container>
        <form onSubmit={handleSubmit(handleChangePassword)}>
          <FormSectionTitle variant="button" display="block" gutterBottom style={PageTitle}>
            Cambio de Clave de acceso
          </FormSectionTitle>
          <Divider sx={StyledDivider} />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                label="Contraseña"
                type="password"
                variant="outlined"
                color="primary"
                margin="dense"
                {...register('password')}
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  style: { fontSize: 14 }
                }}
                error={!!errors?.password_confirmation}
                style={{ marginLeft: '1%', width: '98%' }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                label="Confirmación Contraseña"
                type="password"
                variant="outlined"
                color="primary"
                margin="dense"
                {...register('password_confirmation')}
                InputLabelProps={{ shrink: true }}
                onChange={handleChangePasswordConfirmation}
                error={!!errors?.password_confirmation}
                helperText={errors?.password_confirmation?.message}
                style={{ marginLeft: '1%', width: '98%' }}
              />
            </Grid>
            <Grid item sm={4}></Grid>
            <Grid item xs={6} sm={6} md={2}>
              <Button
                variant="contained"
                style={PrimaryButton}
                type='submit'
              >
                Guardar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </>
  );
};
