export const gridTitle = {
  color: '#2CA7A8',
  textTransform: 'uppercase',
  fontSize: 14
};

export const formSectionTitle = {
  color: '#2CA7A8',
  textTransform: 'uppercase',
  fontSize: 14,
  paddingBottom: 10
};

export const formCardHeader = {
  padding: 0,
  marginRight: 1
};

export const formCardHeaderButton = {
  marginBottom: '10px',
  fontSize: '8pt',
  backgroundColor: '#DDE5EF',
  color: '#333333'
};

export const sectionCard = {
  marginBottom: 10
};

export const gridSearhField = {
  marginRight: 10,
  width: '100%',
  fontSize: '10pt'
};

export const boxResumeCard = {
  display: 'flex',
  alignItems: 'center',
  paddingLeft: 15,
  paddingRight: 15
};

export const dashboardCard = {
  fontSize: 14
};

export const dashboardCardNumber = {
  marginTop: 0,
  marginRight: 5,
  fontWeight: 'bolder',
  fontSize: 28
};

export const dashboardGraphTitle = {
  color: '#2CA7A8',
  fontWeight: 'bold',
  fontSize: 14
};

export const SessionBox = {
  marginLeft: { xs: '5%', sm: '10%', md: '25%' },
  width: { xs: '90%', sm: '80%', md: '50%' },
  marginTop: '24%',
  backgroundColor: '#FFFFFF',
  pt: 4,
  pb: 5,
  pl: 5,
  pr: 5,
  borderRadius: 2
};

export const SessionLogo = {
  width: '15%',
  marginBottom: 30,
  alignContent: 'center',
  marginLeft: '40%',
};

export const SessionHelperText = {
  fontSize: 14,
  color: '#878787',
  display: 'block',
  marginBottom: 10
};

export const SessionFormInput = {
  width: '98%',
  marginTop: '6px',
  marginLeft: '1%',
  marginBottom: '8px'
};

export const SessionLeftSide = {
  backgroundColor: '#FFFFFF'
};

export const SessionBoxTitle = {
  marginBottom: 2
};

export const PrimaryButton = {
  marginLeft: '1%',
  backgroundColor: '#053C5E',
  width: '98%',
  marginRight: '1%',
  fontSize: 12,
  color: '#FFFFFF'
};

export const SecondaryButton = {
  marginLeft: '1%',
  backgroundColor: '#EDF0F2',
  color: '#333333',
  width: '98%',
  marginRight: '1%',
  fontSize: 10
};

export const WarningButton = {
  marginLeft: '1%',
  backgroundColor: '#D67A02',
  width: '98%',
  marginRight: '1%',
  fontSize: 10,
  paddingRight: 10
};

export const SuccessButton = {
  marginLeft: '1%',
  backgroundColor: '#14964C',
  width: '98%',
  marginRight: '1%',
  fontSize: 10,
  paddingRight: 16
};

export const ErrorButton = {
  marginLeft: '1%',
  backgroundColor: '#CC0000',
  fontSize: 10,
  paddingRight: 10
};

export const BoxLinks = {
  alignContent: 'right'
};

export const SessionLink = {
  textDecoration: 'none',
  fontSize: 14,
  marginRight: 10
};

export const HeaderStyles = {
  backgroundColor: '#053C5E',
  minHeight: '30px'
};

export const HeaderSupportButton = {
  fontSize: 10,
  marginRight: 3,
  borderRadius: 10,
  backgroundColor: '#BFDBF7',
  color: '#333333'
};

export const SidebarLink = {
  textDecoration: 'none',
  color: '#333333'
};

export const SidebarIcon = {
  color: '#333333',
  minWidth: 40
};

export const LayoutStyles = {
  marginTop: '2.8%',
  columnGap: '3%',
  paddingRight: '2%',
  overflowX: 'hidden',
  overflowY: 'scroll',
  paddingBottom: '5%',
  height: '99vh',
  marginBottom: '1%'
};

export const FormSubtitle = {
  pt: 1,
  pb: 1,
  pl: 2,
  fontSize: 16,
  color: '#053C5E',
  fontWeight: 'bold'
};

export const FormInputStyle = {
  marginLeft: '.5%',
  width: '99%'
};

export const CalendarEventTitle = {
  fontSize: 9,
  fontWeight: 'bold'
};

export const FormLabel = {
  fontSize: 14,
  fontWeight: 'bold',
  pb: 6,
  pl: 1,
  pt: 6
};

export const PageTitle = {
  color: '#053C5E',
  fontWeight: 'bold',
  fontSize: 16 
};

export const SectionTitle = {
  color: '#053C5E',
  fontWeight: 'bold',
  fontSize: 14,
  marginBottom: 2
};

export const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
};

export const MediaIconStyle = {
  color: 'rgba(255, 255, 255, 0.54)',
  px: 0,
  py: .5
};

export const calendarStatusLink = {
  fontSize: 12,
  fontWeight: 'bold',
  pl: 1,
  pr: 2
};

export const calendarStatusAvatarLink = {
  height: 22,
  width: 22,
  fontSize: 10
};

export const FormActions = {
  marginTop: 10
};

export const GridTitle = {
  color: '#3949AB',
  textTransform: 'uppercase',
  fontSize: 14,
  fontWeight: 'bold'
};

export const GridButton = {
  fontSize: 9,
  marginRight: 2,
  minWidth: 20
};

export const GridSearhField = {
  marginLeft: 10,
  marginRight: 10,
  width: 250,
  fontSize: '10pt'
};

export const SubmitButton = {
  fontSize: 10,
  color: '#FFFFFF'
};

export const SubmitSecondaryButton = {
  fontSize: 10,
  color: '#333333',
  backgroundColor: '#EDF0F2',

};

export const StyledDivider = {
  marginBottom: 2,
  marginTop: 1
};

export const CircularProgressStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  verticalAlign: 'center',
  pt: 30,
  pb: 30
};

export const LinearProgressStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  verticalAlign: 'center'
};

export const FormInputSize = {
  style: { fontSize: 12 }
}

