export class CommonFunctions {
  static MonthFirstDay() {
    const today = new Date();
    return new Date(today.getFullYear(), today.getMonth() , 1);
  }

  static Today() {
    return new Date();
  }

  static Truncate(value, limit) {
    if (value?.length <= limit) {
      return value;
    }

    return value?.slice(0, limit) + "...";
  }

  static RemoveHTMLTags(input) {
    return input?.replace(/(<p[^>]+?>|<p>|<\/p>|<strong>|<\/strong>|<h2>|<\/h2>|<h3>|<\/h3>|)/img, '')?.replace(/&nbsp;/g, ' ');
  }

  static GetFileFormat(input) {
    const stringParts = input.split('.').reverse();
    return stringParts?.length > 0 ? stringParts[0].toLowerCase() : '';
  }

  static GetFileName(input) {
    const stringParts = input.split('/').reverse();
    return stringParts?.length > 0 ? stringParts[0].toLowerCase() : '';
  }

  static GetRangeDatesByMonth(year, month) {
    const today = new Date();
    const monthNumber = month !== undefined ? month + 1 : today.getMonth() + 1;
    const calendarMonth = monthNumber < 10 ? '0' + monthNumber : monthNumber;
    const actualYear =  year ? year : today.getFullYear();
    let nextMonth = monthNumber < 12 ? parseInt(calendarMonth) + 1 : parseInt('01');
    nextMonth = nextMonth < 10 ? '0' + nextMonth : nextMonth;
    const endYear  = monthNumber < 12 ? actualYear : actualYear + 1;

    return { start: `${actualYear}-${calendarMonth}-01`,  end: `${endYear}-${nextMonth}-01` };
  }

  static ProcessChatGptResponse(response) {
    const choices = response.choices?.[0].text?.split(/\n/);
    return choices.filter((choice) => choice.length > 1 && choice !== '#undefined')
  }

  static GetArrayOfElements(limit) {
    return Array.from({length: limit}, (_, i) => i + 1);
  }
}