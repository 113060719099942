import React, { useEffect, useState } from 'react';
import {
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector
} from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';
import { StyledDataGrid } from 'common/styles';

const CustomPagination = () => {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="standard"
      count={pageCount}
      page={page + 1}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
};

export function DataTable({
  height,
  columns,
  rows,
  loadedData,
  pageRows,
  rowHeight,
  headerHeight,
  sx,
  getRowId
}) {
  const [pageSize, setPageSize] = React.useState(pageRows ?? 20);
  const rowHeightOt = rowHeight ? rowHeight : 38;
  const headerHeightOt = headerHeight ? headerHeight : 35;
  const [loading, setLoading] = useState(true);
  const rowsPerPage = pageRows ? [pageRows, 20] : [20];

  useEffect(() => {
    setLoading(loadedData);
  }, [loadedData]);

  return (
    <div style={{ display: 'flex', height: height ?? 850, width: '100%' }}>
      <div style={{ flexGrow: 1 }}>
        <StyledDataGrid
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={rowsPerPage}
          disableSelectionOnClick
          disableExtendRowFullWidth={true}
          loading={loading}
          headerHeight={headerHeightOt}
          rowHeight={rowHeightOt}
          components={{ Pagination: CustomPagination }}
          sx={sx}
          getRowId={getRowId}
        />
      </div>
    </div>
  );
}
