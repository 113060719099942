export const eventStatusLabel = Object.freeze({
  pending: 'Pendiente',
  verified: 'Verificado',
  designed: 'Diseñado',
  approved: 'Aprobado'
});

export const eventStatusList = [
  { name: 'pending', color: '#FA702F' },
  { name: 'verified', color: '#1165ED' },
  { name: 'designed', color: '#5910C7' },
  { name: 'approved', color: '#18AD7E' },
];