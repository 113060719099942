import React, { useContext, useState } from 'react';
import {
  TextField,
  Button,
  Paper,
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  Box,
  Tooltip,
  Divider
} from '@mui/material';
import { Amplify } from 'aws-amplify';
import { useForm } from 'react-hook-form';
import { ChevronLeft, ChevronRight, Delete as DeleteIcon } from '@mui/icons-material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';

import { CalendarContext } from 'contexts';
import {
  FormInputStyle,
  PrimaryButton,
  SecondaryButton,
  StyledCard,
  StyledDivider,
  ErrorButton,
  FormLabel,
  PageTitle
} from 'common/styles';
import { sortBy } from 'lodash';
import { CreateMarketingContentService, DeleteMarketingContentService, GetMarketingContentByIdService, UpdateMarketingContentService } from '../services';
import { hasAdminPrivileges, useCurrentOrganization, useCurrentToken } from 'common/hooks';
import { socialMediaList } from '../utils/SocialMediaLabel';
import { useEffect } from 'react';
import { ConfirmModal } from 'common/components/ConfirmModal';
import { positions } from '../utils';
import { s3config } from 'common/config';
import { GetMarketingCategoriesService } from 'modules/marketing-categories/services';

export const MarketingStrategyForm = () => {
  Amplify.configure(s3config);

  const {
    eventDate,
    eventAction,
    currentEvent,
    setCurrentEvent,
    setEventDate,
    events
  } = useContext(CalendarContext);
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const requestToken = useCurrentToken();
  const currentOrganization = useCurrentOrganization();
  const [postType, setMarketingCategory] = useState(0);
  const [platforms, setPlatforms] = useState([]);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [modalMessage, setModalMessage] = useState();
  const [marketingCategories, setMarketingCategories] = useState([]);
  const [platformsErrorMessage, setPlatformsErrorMessage] = useState(false);
  const { register, handleSubmit, formState: { errors }, clearErrors, setValue } = useForm();
  let organizationId = currentOrganization?.Id;

  const handleOpenModal = () => setConfirmationModal(true);

  const handleCloseModal = () => setConfirmationModal(false);

  const handleChangeEventDate = (newValue) => setEventDate(newValue);

  const handleChangeMarketingCategory = (event) => {
    setMarketingCategory(event.target.value);
    if (event.target.value !== '0') {
      clearErrors('Type');
    }
  }

  const handlePlatforms = (event) => {
    if (event.target.checked) {
      setPlatforms([...platforms, event.target.value]);
    }
    else {
      const data = platforms.filter((p) => p !== event.target.value);
      setPlatforms(data);
    }
    if (platforms?.length > 0) {
      setPlatformsErrorMessage(false);
    }
  };

  const handleDeleteEvent = () => {
    DeleteMarketingContentService({ id: currentEvent?.Id, token: requestToken })
      .then(() => {
        handleCloseModal();
        navigate('/calendario-contenido');
        enqueueSnackbar('Registro eliminado con exito!', { variant: `success` });
      })
      .catch(() => {
        enqueueSnackbar('Se ha producido un error', { variant: `error` });
      });
  };

  const getMarketingContentInfo = (position) => {
    const index = events?.findIndex((event) => event.Id === currentEvent?.Id);
    const newIndex = position === 'previous' ? index - 1 : index + 1;
    const schedule = (index === 0 && position === 'next') || (index > 0) ? events[newIndex] : undefined;

    if (schedule) {
      navigate(`/calendario-contenido/${schedule.Id}`);
    }
  };

  useEffect(() => {
    if (params?.id !== undefined) {
      GetMarketingContentByIdService({ id: params.id }).then((response) => {
        setCurrentEvent(response);
        setEventDate(response.PublishAt);
        setMarketingCategory(response.CategoryId);
        setPlatforms([...response.Platforms]);
        setValue('Title', response.Title);
        setValue('CategoryId', response.CategoryId);
        setValue('AssignedTo', response.AssignedTo);
      });
    }
  }, [params]);

  useEffect(() => {
    organizationId = currentOrganization?.Id;
    const params = { organization_id: organizationId };
    GetMarketingCategoriesService({
      params: params,
      token: requestToken
    }).then((response) => {
      setMarketingCategories(sortBy(response, ['Name', 'asc']));
    });
  }, [organizationId])

  const checkFormErrors = () => {
    let isOk = true;

    if (platforms?.length < 1) {
      setPlatformsErrorMessage(true);
      isOk = false;
    }
    return isOk;
  }

  const onSubmit = async (data, e) => {
    e.preventDefault();

    if (!checkFormErrors(data)) {
      return;
    }

    data.OrganizationId = organizationId;
    data.PublishAt =  eventDate;
    data.Platforms = platforms;

    if (eventAction === 'create') {
      CreateMarketingContentService({ params: data, token: requestToken })
      .then(() => {
        navigate('/calendario-contenido');
        enqueueSnackbar('Post registrado con exito!', { variant: `success` });
      })
      .catch(() => {
        enqueueSnackbar('Se ha producido un error', { variant: `error` });
      });
    }
    else {
      data.Id = currentEvent?.Id;
      UpdateMarketingContentService({
        id: currentEvent?.Id,
        params: data,
        token: requestToken
      })
      .then(() => {
        navigate('/calendario-contenido');
        enqueueSnackbar('Post actualizado con exito!', { variant: `success` });
      })
      .catch(() => {
        enqueueSnackbar('Se ha producido un error', { variant: `error` });
      });
    }
  };

  return (
    <StyledCard>
      <Grid container>
        <Grid item xs={8} md={8}>
          <Typography style={PageTitle}>Nuevo Contenido</Typography>
        </Grid>
        <Grid item xs={12} md={4}>
        <Box textAlign="right">
          {eventAction === 'update' && (
            <>
              <Tooltip title="Anterior">
                <Button
                  startIcon={<ChevronLeft />}
                  size="small"
                  onClick={() => getMarketingContentInfo(positions.previous)}
                ></Button>
              </Tooltip>
              <Tooltip title="Siguiente">
                <Button
                  startIcon={<ChevronRight />}
                  size="small"
                  onClick={() => getMarketingContentInfo(positions.next)}
                ></Button>
              </Tooltip>
              {hasAdminPrivileges() && (
                <Button
                  variant="contained"
                  style={ErrorButton}
                  size="small"
                  startIcon={<DeleteIcon />}
                  onClick={() => {
                    setModalMessage('¿Confirma que desea eliminar el post?');
                    handleOpenModal();
                  }}
                >
                  Eliminar
                </Button>
              )}
            </>
            )}
          </Box>
        </Grid>
      </Grid>
      <Divider sx={StyledDivider} />
        <Grid container spacing={3} sx={{ marginTop: 0.5 }}>
          <Grid item xs={12} sm={12} md={8} sx={{ pr: 2 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                  <Paper elevation={1}>
                    <FormControl margin="none" style={{ width: '100%' }} >
                      <InputLabel id="format-label" sx={{ fontSize: 14 }}>Categoria</InputLabel>
                      <Select
                        labelId="format-label"
                        {...register('CategoryId', {
                          minLength: { value: 2, message: 'Debe seleccionar un formato' }
                        })}
                        inputProps={{ style: { fontSize: 12 }}}
                        onChange={(e) => handleChangeMarketingCategory(e)}
                        color="primary"
                        size="small"
                        value={postType}
                        sx={{ fontSize: 12 }}
                        label="Categoria"
                        error={!!errors?.Type}
                      >
                        <MenuItem value={0} sx={{ fontSize: 12 }}>Seleccione...</MenuItem>
                        {marketingCategories?.map((category) => {
                          return (
                            <MenuItem key={category.Name} value={category.Id} sx={{ fontSize: 12 }}>
                              {category.Name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {errors?.Type && (
                        <FormHelperText id="format-helper-text" error>
                          {errors?.Type?.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={6}></Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography style={FormLabel}>Seleccione las redes sociales:</Typography>
                  {platformsErrorMessage && (
                      <FormHelperText id="format-helper-text" error>Debe seleccionar al menos una red social</FormHelperText>
                    )}
                  {socialMediaList.map((sm) => (
                    <FormControlLabel
                      key={`label-${sm.name}`}
                      control={
                        <Checkbox
                          label={sm.name}
                          value={sm.name}
                          key={sm.name}
                          onClick={handlePlatforms}
                          checked={platforms.filter((p) => p === sm.name ).length > 0}
                        />
                      }
                      labelPlacement="start"
                      label={
                        <Tooltip title={sm.name}>
                          <img src={`${process.env.PUBLIC_URL}/img/${sm.name}.png`} height="24" alt={sm.name} />
                        </Tooltip>
                      }
                    />
                  ))}
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    label="Título"
                    type="text"
                    variant="outlined"
                    color="primary"
                    margin="dense"
                    {...register('Title', {
                      required: { value: true, message: 'Campo Requerido' }
                    })}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ style: { fontSize: 12 } }}
                    error={!!errors?.Title}
                    helperText={errors?.Title?.message}
                    style={{ marginLeft: '0.5%', width: '99%' }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}></Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Paper elevation={1}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <FormControl style={{ width: '100%' }} margin="none">
                        <DateTimePicker
                          fullWidth
                          label="Fecha Publicación"
                          value={eventDate || null}
                          style={FormInputStyle}
                          onChange={handleChangeEventDate}
                          disablePast
                          inputProps={{ style: { fontSize: 12 }}}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              InputLabelProps={{ shrink: true }}
                              size="small"
                            />
                          )}
                        />
                      </FormControl>
                    </LocalizationProvider>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={6}></Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    label="Responsable"
                    type="text"
                    variant="outlined"
                    color="primary"
                    margin="dense"
                    {...register('AssignedTo', {
                      required: { value: true, message: 'Campo Requerido' }
                    })}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ style: { fontSize: 12 } }}
                    error={!!errors?.AssignedTo}
                    helperText={errors?.AssignedTo?.message}
                    style={{ marginLeft: '0.5%', width: '99%' }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={6} sx={{ marginTop: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <Button variant="contained" type="submit" style={PrimaryButton}>Guardar</Button>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Button variant="contained" style={SecondaryButton} onClick={() => navigate('/calendario-contenido')}>Cancelar</Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
        <ConfirmModal
          open={confirmationModal}
          onClose={handleCloseModal}
          handleCloseModal={handleCloseModal}
          message={modalMessage}
          onConfirm={() => handleDeleteEvent()}
        />
    </StyledCard>
  );
};
