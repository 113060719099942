export const SpanishLanguage = {
  next: 'Sig',
  previous: 'Ant',
  today: 'Hoy',
  month: 'Mes',
  week: 'Semana',
  day: 'Día'
};

export const EnglishLanguage = {
  next: 'Next',
  previous: 'Prev',
  today: 'Today',
  month: 'Month',
  week: 'Week',
  day: 'Day'
};