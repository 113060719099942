import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { StyledCard } from 'common/styles';
import { LinkedinForm } from '../components';
import { useCurrentOrganization } from 'common/hooks';

export const SocialNetworkLinkedin = ({video}) => {
  const currentOrganization = useCurrentOrganization();

  return (
    <StyledCard elevation={0}>
      <Box sx={{ flexGrow:1 }}>
        <Grid container spacing={1}>
        {currentOrganization?.LinkedinAccessToken === undefined ?
          (
            <strong><i>Necesita autorizar la integración, consulte con el manager de la organización...</i></strong>
          )
          :
          (
            <>
              <Grid item sm={12}>
                <Typography sx={{ fontWeight: 'bold', fontSize: 12, marginBottom: 2 }} textAlign="right">
                  Cuenta integrada: {currentOrganization?.LinkedinProfile?.AccountInfo}
                </Typography>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={10} style={{ marginTop: 5 }}>
                  <Box  sx={{ flexGrow: 1 }}>
                    <LinkedinForm video={video} />
                  </Box>
                </Grid>
              </Grid>
            </>
          )
        }
        </Grid>
      </Box>
    </StyledCard>
  )
}
