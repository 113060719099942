import {
  AxiosServicePost,
  AxiosServiceGet,
  AxiosServiceDelete,
  AxiosServiceUpdate
} from 'common/services/AxiosBaseService';

const baseUrl = process.env.REACT_APP_COVACHA_SCHEDULE;
const apiUrl = 'v1/notes';

export const GetNotesService = async ({ params, token }) => {
  return await AxiosServiceGet(baseUrl, apiUrl, params, token);
};

export const UpdateNote = async ({ id, params, token }) => {
  return await AxiosServiceUpdate(baseUrl, `${apiUrl}/${id}`, params, token);
};

export const CreateNoteService = async (data, token) => {
  return await AxiosServicePost(baseUrl, apiUrl, data, token);
};

export const DeleteNoteservice = async (id, token) => {
  return await AxiosServiceDelete(baseUrl, `${apiUrl}/${id}`, token);
};
