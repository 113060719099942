import { CommonFunctions } from 'common/utils';
import React from 'react';

export const MediaComponent = ({ file, height, width, onClick }) => {
  const videoExtensions = ["mov", "mp4", "avi", "ogg"];
  const imageExtensions = ["jpeg", "jpg", "gif", "png", "svg", "webp"];
  const fileExtension = CommonFunctions.GetFileFormat(file?.key);

  const imageComponent = () => (
    <img
      src={`${process.env.REACT_APP_BUCKET_URL}${file.key}`}
      height={height ?? 100}
      width={width ?? 'auto'}
      onClick={onClick}
    />
  );

  const videoComponent = () => (
    <video width={width ?? 'auto'} height={ height ?? 100} controls>
      <source src={`${process.env.REACT_APP_BUCKET_URL}${file.key}`} type={`video/${fileExtension}`} />
    </video>
  );

  if (fileExtension?.length > 1) {
    if (imageExtensions.some((ext) => ext === fileExtension)) {
      return imageComponent();
    }

    if (videoExtensions.some((ext) => ext === fileExtension)) {
      return videoComponent();
    }
  }
};