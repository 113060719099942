import React,{ useContext, useEffect } from 'react';
import { Button, FormControl, Grid, TextField } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { FormInputSize, FormInputStyle, PrimaryButton } from 'common/styles';
import { AppContext, CalendarContext } from 'contexts';
import { useForm } from 'react-hook-form';
import { useCurrentToken } from 'common/hooks';
import { SharePost } from '../services';
import { useSnackbar } from 'notistack';
export const SinglePost = ({post}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { register, handleSubmit, formState: { errors }, setValue  } = useForm();//getValues, 
  const { eventDate, } = useContext(CalendarContext);
  const { getCurrentUser } = useContext(AppContext);
  const user = getCurrentUser();
  const requestToken = useCurrentToken();

  useEffect(() => {
    setValue('Title', post.Title);
    setValue('Description', post.Description);    
  }, [])
  
  const handleChange = () => {
    return false;
  }
  const onSubmit = async (data, e) => {
    e.preventDefault();
    const params = {
      author: `${user.userLinkedin.id_linkedin}`,
      token_linkedin: `${user.userLinkedin.access_token}`,
      commentary: data.Description,
    };

    SharePost({params: params, token: requestToken })
    .then((response) => {
      console.log(response)
      if(response.statusCode === 200){
        enqueueSnackbar('Post publicado con exito!', { variant: `success` });
      }
    }, (error) => {
      enqueueSnackbar('Se ha producido un error', { variant: `error` });
      console.log(error)
    });
    return false;
  };
  return (
    <Grid container>
        <Grid item xs={12} sm={12} md={12} style={{ marginTop: 25 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <FormControl style={{ width: '100%' }}>
                      <DateTimePicker
                        fullWidth
                        label="Fecha Publicación"
                        value={eventDate || null}
                        style={FormInputStyle}
                        // onChange={handleChangeEventDate}
                        disablePast
                        inputProps={FormInputSize}
                        renderInput={(params) => (
                          <TextField {...params} InputLabelProps={{ shrink: true }} size="small" />
                        )}
                      />
                    </FormControl>
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <FormControl style={{ width: '100%' }} margin="dense">
                    <TextField
                      {...register('Title', {
                        minLength: { value: 2, message: 'Debe ingresar el titulo del video.' }
                      })}
                      id="outlined-multiline-flexible"
                      label="Título"
                      labelId="format-label"
                      error={!!errors?.Title}
                      fullWidth
                      style={FormInputStyle}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ style: { fontSize: 12 }}}
                      type="text"
                      sx={{fontSize:12}}
                      color="primary"
                      size='meddium'
                      onChange={handleChange}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <FormControl style={{ width: '100%' }} margin="dense">
                    <TextField
                      {...register('Description', {
                        minLength: { value: 2, message: 'Debe ingresar la descripción del post.' }
                      })}
                      id="outlined-multiline-flexible"
                      label="Descripción"
                      multiline
                      rows={10}
                      error={!!errors?.Description}
                      fullWidth
                      style={FormInputStyle}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        style: { fontSize: 12 },
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6} sx={{ marginTop: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <Button variant="contained" type="submit" style={PrimaryButton}>Enviar</Button>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  )
}
