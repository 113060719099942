import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import {
  AppBar,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Box,
  CssBaseline
} from '@mui/material';
import { useIdleTimer } from 'react-idle-timer';
import { AccountCircle, Menu as MenuIcon } from '@mui/icons-material';
import { HeaderSelect, HeaderListIem, HeaderMenuItem } from 'common/styles';
import { AppContext } from 'contexts';
import { useUserOrganizations } from 'common/hooks';
import { Support } from './support';
import { HeaderStyles } from '../styles';
import { GetOrganizationByIdService } from 'modules/organizations/services';

export const Header = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const {
    openSidebar,
    getCurrentUser,
    setCurrentUser,
    getCurrentOrganization,
    setCurrentOrganization,
    setIsIdle,
    setOpenSidebar,
    setCalendarDefaultView
  } = useContext(AppContext);
  const timeout = 30 * 60 * 1000;
  const user = getCurrentUser();
  const selectedOrganization = getCurrentOrganization();
  const userOrganizations = useUserOrganizations();
  const [open, setOpen] = useState(false);

  const handleDrawerToggle = () => setOpenSidebar(!openSidebar);

  const handleOnActive = () => setIsIdle(false);

  const handleMenu = (event) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const handleOnIdle = () => {
    handleLogout();
    setIsIdle(true);
  };

  useIdleTimer({
    timeout,
    onActive: handleOnActive,
    onIdle: handleOnIdle
  });

  const handleSelectOrganization = (event) => {
    const organizationId = event?.target.value;
    GetOrganizationByIdService({ id: organizationId })
    .then((response) => {
      setCurrentOrganization(response);
    })
    .catch((error) => {
      console.log(error);
    });

    setCalendarDefaultView('month');
    navigate('/calendario');
  };

  async function signOut() {
    try {
      await Auth.signOut();
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  const handleLogout = () => {
    localStorage.clear();
    setCurrentOrganization();
    setCurrentUser(null);
    setAnchorEl(null);
    signOut();
    navigate(`/`);
  };

  const handleProfileView = () => {
    handleClose();
    navigate(`/usuarios/${user?.info?.sub}`);
  };

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" style={HeaderStyles} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <Support setOpen={setOpen} open={open} />
            <Toolbar variant="dense">
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 1 }}
              >
                <MenuIcon />
              </IconButton>
            </Box>
              <Box display="flex" flexGrow={1}>
                <img src={`${process.env.PUBLIC_URL}/img/covacha.png`} alt="Logo" width={40} />
              </Box>
              <div>
                {selectedOrganization && (
                  <HeaderSelect
                    size="small"
                    value={selectedOrganization?.Id}
                    sx={{ width: { sx: 160, sm: 160, fontSize: 12 } }}
                    onChange={(e) => handleSelectOrganization(e)}
                  >
                    {userOrganizations?.map((organization) => (
                      <HeaderListIem key={organization.id} value={organization.id} sx={{ fontSize: 12 }}>
                        {organization.name}
                      </HeaderListIem>
                    ))}
                  </HeaderSelect>
                )}
                <span style={{ fontSize: 12 }}>
                  {user && ` ${user?.info?.email}`}
                </span>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  keepMounted
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  style={{ marginTop: '32px' }}
                >
                  <MenuItem onClick={handleProfileView}>
                    <HeaderMenuItem textAlign="center" sx={{ fontSize: 12 }}>Mi Cuenta</HeaderMenuItem>
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>
                    <HeaderMenuItem textAlign="center" sx={{ fontSize: 12 }}>Cerrar sesión</HeaderMenuItem>
                  </MenuItem>
                </Menu>
              </div>
            </Toolbar>
        </AppBar>
      </Box>
    </>
  );
};
