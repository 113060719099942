import {
  AxiosServiceGet,
  AxiosServiceUpdate,
  AxiosServicePost, 
  AxiosServiceDelete
} from 'common/services/AxiosBaseService';

const baseUrl = process.env.REACT_APP_COVACHA_SCHEDULE;
const apiUrl = 'v1/marketing-strategies';

export const GetMarketingContentsService = async ({ params, token }) => {
  return await AxiosServiceGet(baseUrl, apiUrl, params, token);
};

export const GetMarketingContentByIdService = async ({ id, token }) => {
  return await AxiosServiceGet(baseUrl, `${apiUrl}/${id}`, null, token);
};

export const CreateMarketingContentService = async ({ params, token }) => {
  return await AxiosServicePost(baseUrl, `${apiUrl}`, params, token);
};

export const DeleteMarketingContentService = async ({ id, token }) => {
  return await AxiosServiceDelete(baseUrl, `${apiUrl}/${id}`, token);
};

export const UpdateMarketingContentService = async ({ id, params, token }) => {
  return await AxiosServiceUpdate(baseUrl, `${apiUrl}/${id}`, params, token);
};