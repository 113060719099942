
import React from 'react'
import { Box, CardActions, Grid, Typography } from '@mui/material'
import { SocialMediaCard, SocialMediaCardContent, StyledCard } from 'common/styles'
import { LinkedinButtonForLogin, LinkedinButtonForLogout } from '.'
import { useCurrentOrganization } from 'common/hooks'

export const LinkedinAuthorizationForm = () => {
  const currentOrganization = useCurrentOrganization();

  return (
    <StyledCard elevation={0}>
      <Box sx={{ flexGrow:1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} key={'linkedin'}>
            <SocialMediaCard key={'linkedin'}>
              <img
                src={`${process.env.PUBLIC_URL}/img/linkedin.png`}
                style={{ width: 50, height: 50, marginLeft: '42%', marginTop:2 }}
              />
              <SocialMediaCardContent sx={{ pt: '2px', pb: '2px' }}>
                <Typography alignContent="center" alignItems="center" style={{ fontSize: 16, fontWeight: 'bold' }}>LinkedIn</Typography>
                {
                  currentOrganization?.LinkedinProfile
                    ? currentOrganization?.LinkedinProfile?.AccountInfo
                    : <p></p>
                }
              </SocialMediaCardContent>
              <CardActions sx={{ justifyContent: 'center' }}>
                {
                  currentOrganization?.LinkedinAccessToken
                    ? <LinkedinButtonForLogout />
                    : <LinkedinButtonForLogin />
                }
              </CardActions>
            </SocialMediaCard>
          </Grid>
        </Grid>
      </Box>
    </StyledCard>
  )
}
