import styled from 'styled-components';
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  TextField,
  Box,
  Select,
  MenuItem,
  Typography
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

export const StyledGrid = styled(Grid)`
  height: 580px;
  width: 100%;
  margin-top: 5%;
`;

export const StyledCard = styled(Card)`
  width: 100%;
  margin-bottom: 20px;
  padding: 10px;
`;

export const StyledFormCard = styled(Card)`
  margin-top: 1%;
  width: 100%;
  margin-bottom: 20px;
  padding: 15px;
`;

export const StyledCardHeader = styled(CardHeader)`
  variant: h6;
  padding-top: 5px;
  padding-bottom: 5px;
`;

export const StyledTextField = styled(TextField)`
  margin-top: 6px;
  margin-left: 1%;
  width: 98%;
  margin-bottom: 8px;
`;

export const Root = styled('div')`
  height: 100%;
  background-color: #000;
`;

export const StyledBox = styled(Box)`
  background-color: #000;
`;

export const Puller = styled(Box)`
  width: 30;
  height: 6;
  background-color: #000;
  borderradius: 3;
  position: 'absolute';
  top: 8;
  left: 'calc(50% - 15px)';
`;

export const DashboardResumeCard = styled(Card)`
  background-color: #f3f2f5;
  margin-right: 1%;
  display: flex;
  height: 80px;
`;

export const DashboardResumeCardContent = styled(CardContent)`
  flex: 1 0 auto;
`;

export const CardAllLeads = styled(Card)`
  border-left: 6px solid #AEAEAE;
  background-color: #f3f2f5;
  height: 120px;
  margin-right: 1%;
`;

export const CardNewLeads = styled(Card)`
  border-left: 6px solid #00bd91;
  background-color: #ebfffa;
  height: 120px;
  margin-right: 1%;
`;

export const CardUnassignedLeads = styled(Card)`
  border-left: 5px solid #f07800;
  background-color: #fff9f2;
  height: 120px;
  margin-right: 1%;
`;

export const CardOnsaleLeads = styled(Card)`
  border-left: 5px solid #ede500;
  background-color: #fffed6;
  height: 120px;
  margin-right: 1%;
`;

export const CardSoldLeads = styled(Card)`
  border-left: 5px solid #e3094a;
  background-color: #ffe3eb;
  height: 120px;
`;

export const CardSold = styled(Card)`
  border-left: 5px solid #f00;
  height: 120px;
  margin-bottom: 10px;
`;

export const SubscriptionCard = styled(Card)`
  height: 330px;
  margin-bottom: 10px;
  margin-right: 2%;
  width: 80%;
`;

export const SubscriptionCardContent = styled(CardContent)`
  height: 240px;
`;

export const OrganizationCard = styled(Card)`
  height: 250px;
  background-color: #f3f2f5;
`;

export const OrganizationCardContent = styled(CardContent)`
  margin-top: 55px;
`;

export const SocialMediaCard = styled(Card)`
  background-color: #f3f2f5;
`;

export const SocialMediaCardContent = styled(CardContent)`
  margin-top: 0px;
`;

export const StyledDataGrid = styled(DataGrid)`
  .MuiDataGrid-cell--textLeft {
    justify-content: center;
    font-size: 10pt;
  }
`;

export const HeaderSelect = styled(Select)`
  font-size: 9pt;
  background-color: #ffffff;
`;

export const HeaderListIem = styled(MenuItem)`
  font-size: 9pt;
`;

export const HeaderMenuItem = styled(Typography)`
  font-size: 10pt;
`;

export const FormSectionTitle = styled(Typography)`
  color: #2ca7a8;
  letter-spacing: 0;
`;

export const FormCardHeader = styled(CardHeader)`
  padding: 5px 0;
`;

export const SideSectionTitle = styled(Typography)`
  color: #2ca7a8;
  font-weight: bold;
  letter-spacing: 0;
`;

export const StyledUserCardHeader = styled(CardHeader)`
  padding: 5px 0;
`;

export const StyledDropZone = styled(Box)`
  background-color: #f1f3f6;
  width: 100%;
  text-align: center;
  padding: 5px;
  font-size: 9pt;
  font-weight: bold;
  border: 1px #c1c1c2 dashed;
  color: #b6b6ba;
  padding-bottom: 15px;
`;

export const StyleDragArea = styled.div`
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.file-upload-content {
  display: none;
  text-align: center;
}
.file-upload-input {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
  cursor: pointer;
}
.image-upload-wrap {
  position: relative;
  height: 50px;
  border: 4px solid #d0d7de;
  margin-left: 0px;
  margin-right: 0px;
}
.image-upload-wrap:hover {
  background-color: transparent;
  border: 4px dashed #d0d7de;
}
.text-information {
  margin-top: -10px;
  text-align: center;
}
`;