import React from 'react';
import { Box, LinearProgress, Typography } from '@mui/material';
import { LinearProgressStyle } from 'common/styles';

export function CustomLinearProgress({ text}) {

  return (
    <Box component="span" sx={LinearProgressStyle}>
      <Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>{text}</Typography>
      <LinearProgress />
    </Box>
  );
}
