import React, { useEffect, useState } from 'react'
import { FormControl, Grid, InputLabel, MenuItem, Select, Typography} from '@mui/material'
import {  FormInputSize } from 'common/styles'
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { GetScheduleByIdService } from 'modules/calendar/services';
import { SinglePost } from './SinglePost';
import { LinkPost } from './LinkPost';
import { ImagePost } from './ImagePost';
import { VideoPost } from './VideoPost';

export const LinkedinForm = ({video}) => {
  const [show, setShow] = useState('');
  const { register, setValue } = useForm();
  const [post, setPost] = useState({});
  const params = useParams();
  const handleChangeTypePost = (e) => {
    setShow(e.target.value);
  }

  useEffect(() => {
    if(params?.id !== undefined){
      GetScheduleByIdService({ id: params.id }).then((response) => {
        setPost(response);
        setValue('Title', response.Title);
        setValue('Copy', response?.Copy);
        setValue('Description', response.Description);
      })
    }
  },[])
  return (
    <Grid item xs={12} sm={12} md={12} style={{marginTop: -1}}>
      <Typography variant='h6' sx={{ fontWeight:'bold', fontSize:16, marginBottom:2 }}>Que tipo de post desea públicar?</Typography>
      <Grid item xs={12} sm={12} md={12}>
        <FormControl margin="none" style={{ width: '100%' }} >
          <InputLabel id="format-label" sx={{ fontSize: 14 }}>Tipo De Post</InputLabel>
          <Select
            labelId="format-label"
            {...register('TypePost', {
              minLength: { value: 2, message: 'Debe seleccionar un formato' }
            })}
            inputProps={FormInputSize}
            onChange={(e) => handleChangeTypePost(e)}
            color="primary"
            sx={{ fontSize: 12 }}
            label="Tipo de Post"
          >
            <MenuItem value="" sx={{ fontSize: 12 }}>Seleccione...</MenuItem>
            <MenuItem value="Texto" sx={{ fontSize: 12 }}>Texto</MenuItem>
            <MenuItem value="Enlace" sx={{ fontSize: 12 }}>Enlace</MenuItem>
            <MenuItem value="Imagen" sx={{ fontSize: 12 }}>Imagen</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      { show === 'Texto'   && <SinglePost post={post}/> }
      { show === 'Enlace'  && <LinkPost post={post}/>   }
      { show === 'Imagen'  && <ImagePost post={post} />  }
      { show === 'Video'   && <VideoPost video={video} post={post}/>  }
    </Grid>
  )
}