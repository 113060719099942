import React from 'react';
import { Box } from '@mui/system';
import { Header } from './Header';
import LayoutSidebar from './Sidebar';
import { MainContainer } from './MainContainer';
import { Outlet } from 'react-router-dom';
import { LayoutStyles } from '../styles';
import { CalendarProvider } from '../../contexts';

export const Layout = () => {
  return (
    <Box style={LayoutStyles}>
      <Header />
      <LayoutSidebar />
      <Box
        sx={{
          marginTop: { xs: '14%', sm: '1%' },
          marginLeft: { xs: '25px', sm: '260px' },
          width: { xs: `calc(100% - ${25}px)`, sm: `calc(100% - ${250}px)` }
        }}
      >
        <CalendarProvider>
          <Outlet>
            <MainContainer />
          </Outlet>
        </CalendarProvider>
      </Box>
    </Box>
  );
};
