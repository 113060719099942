export const socialMediaColorLabel = Object.freeze({
  twitter: '#1DA1F2',
  facebook: '#4267B2',
  instagram: '#833AB4',
  youtube: '#FF0000',
  tiktok: '#ff0050',
  linkedin: '#0e76a8',
});

export const socialMediaList = [
  { name: 'twitter', color: '#1DA1F2' },
  { name: 'facebook', color: '#4267B2' },
  { name: 'instagram', color: '#833AB4' },
  { name: 'youtube', color: '#FF0000' },
  { name: 'tiktok', color: '#ff0050' },
  { name: 'linkedin', color: '#0e76a8' }
];