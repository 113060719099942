import React from 'react';
import { List, ListItem, ListItemText, IconButton, Typography, Grid, Button, Box, Tooltip, Divider } from '@mui/material';
import { TaskAlt as SelectIcon, WebAssetOff as HideIcon } from '@mui/icons-material';
import { StyledDivider } from 'common/styles';

export const CustomList = ({ title, name, elements, onClick, showList }) => {
  return (
    <>
      <Grid container>
        <Grid item xs={6} md={10}>
          <Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>{title}</Typography>
        </Grid>
        <Grid item xs={6} md={2}>
          <Box display="flex"  style={{ justifyContent: 'end', flexWrap: 'wrap' }} textAlign="right">
            <Tooltip title="Ocultar">
              <Button size="small" endIcon={<HideIcon />} onClick={() => showList(false)} />
            </Tooltip>
          </Box>
        </Grid>
      </Grid>
      <Divider style={StyledDivider} />
      <List>
        {elements?.map((element) => (
          <ListItem
            secondaryAction={
              <Tooltip title="Seleccionar">
                <IconButton edge="end" aria-label="Seleccionar">
                  <SelectIcon onClick={() => onClick(name, element)} />
                </IconButton>
              </Tooltip>
            }
            key={element}
          >
            <ListItemText
              key={`text-${element}`}
              primary={element}
              primaryTypographyProps={{ style: { fontSize: 12 }}}
            />
          </ListItem>
        ))}
      </List>
    </>
  );
};
