import React, { useState, useEffect, useContext } from 'react'
import { Button, FormControl, Grid, TextField} from '@mui/material'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { FormInputStyle, FormLabel, PrimaryButton, StyleDragArea } from 'common/styles'
import { useForm } from 'react-hook-form';
import { AppContext } from 'contexts';
import axios from 'axios';
import { CalendarContext } from 'contexts';
import { MuiChipsInput } from 'mui-chips-input';
import { CreateSocialPostService, LabelsTrends } from 'modules/social-media';
import { upVideo } from 'common/config';
import { useSnackbar } from 'notistack';
import { CommonFunctions } from 'common/utils';
import { useCurrentOrganization, useCurrentToken } from 'common/hooks';

export const YoutubeForm = ({video}) => {
  const { enqueueSnackbar } = useSnackbar();
  const curretnOrganization = useCurrentOrganization();
  const { register, handleSubmit, setValue, formState: { errors } } = useForm();
  const [uploadVideo, setUploadVideo] = useState(null);
  const urlVideo = `${process.env.REACT_APP_BUCKET_URL}${video}`;
  const { getCurrentUser } = useContext(AppContext);
  const requestToken = useCurrentToken();
  const organizationId = useCurrentOrganization();
  const user = getCurrentUser();
  const token = curretnOrganization?.YoutubeAccessToken;
  const [baseURL, setBaseURL] = useState(upVideo);
  const [videoDetails, setVideoDetails] = useState({
    Title:'',
    Description:'',
    file: null
  });
  const { eventDate, setEventDate } = useContext(CalendarContext);
  const [media, setMedia] = useState(null);
  let headers = {
    "Content-type": "application/json; charset=UTF-8",
    "Authorization": 'Bearer ' + token,
    "X-Upload-Content-Type": "video/*"
  };
  const [chips, setChips] = useState([])
  const handleChangeChip = (newChips) => {
    setChips(newChips)
  };

  const handleChange = (e) => {
    const inputValue = e.target.name === "file" ? e.target.files[0] : e.target.value;
    setVideoDetails({
      ...videoDetails,
      [e.target.name] : inputValue
    });
    const reader = new FileReader();
    const selectedFile = e.target.name === "file" ? e.target.files[0] : undefined;
    if (selectedFile) {
      reader.readAsDataURL(selectedFile);
    }
    reader.onload = (readerEvent) => {
      if (selectedFile.type.includes("video")) {
        setUploadVideo(readerEvent.target.result);
      }
    };
  }

  const sendData = async (videoDetails) => {
    setMedia(videoDetails?.file);
    const response = await axios.post(baseURL, {
        "kind": "youtube#video",
        "snippet":{
            "title":videoDetails.Title,
            "description":videoDetails.Description,
            "publishedAt": videoDetails.publishedAt,
            "tags": videoDetails.tags
        },
        "status": {
            "privacyStatus": "public"
        }

    }, {headers:headers});
    if(response.status === 200){
      setBaseURL(response.headers.location);
      enqueueSnackbar('Iniciando carga de archivo', { variant: `success` });
    }
  };

  const handleChangeEventDate = (newValue) => setEventDate(newValue);

  const registerPost = (videoInfo) => {
    const params = {
      Platform: 'Youtube',
      Url: `${process.env.REACT_APP_YOUTUBE_BASE_URL}${videoInfo?.data?.id}`,
      OrganizationId: organizationId,
      ChannelId: videoInfo.data.snippet.channelId,
      PublishAt: videoInfo.data.snippet.PublishAt,
      CreatedAt: CommonFunctions.Today(),
      CreatedBy: user?.info?.sub
    };
    CreateSocialPostService({
      params: params,
      token: requestToken
    });
  }

  useEffect(() => {
    setUploadVideo(urlVideo)
  },[urlVideo])

  const resetFormValues = () => {
    setValue('Title', '');
    setValue('Description', '');
    setValue('file', null);
    videoDetails.file = null;
    setUploadVideo(null);
    setChips([]);
  };

  useEffect(() => {
    if(baseURL != upVideo){
      const sendVideo = async () => {
        setTimeout(() => {
          axios.put(baseURL, media, {headers:{'Content-Type': 'video/*','Authorization': 'Bearer ' + token}})
          .then((response) => {
              resetFormValues();
              registerPost(response);
              enqueueSnackbar('Archivo cargado con exito.', { variant: `success` });
            })
            .catch(() => {
              enqueueSnackbar('Error al intentar cargar el archivo!', { variant: `error` });
            });
        }, 10000)
      }
      sendVideo();
    }
  }, [baseURL]);

  const onSubmit = () => {
    videoDetails.publishedAt = eventDate.toISOString();
    videoDetails.tags = chips;
    sendData(videoDetails);
    return false;
  };

  return (
    <Grid item xs={12} sm={12} md={12} style={{marginTop: -1}}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <FormControl style={{ width: '100%' }} margin="dense">
                  <TextField
                    labelId="format-label"
                    {...register('Title', {
                      minLength: { value: 2, message: 'Debe ingresar el titulo del video.' }
                    })}
                    fullWidth
                    inputProps={{ style: { fontSize: 12 }}}
                    label="Titulo"
                    id="title"
                    type="text"
                    sx={{fontSize:12}}
                    color="primary"
                    size='meddium'
                    error={!!errors?.Title}
                    onChange={(e) => handleChange(e)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <FormControl style={{ width: '100%' }} margin="dense">
                  <TextField
                    {...register('Description', {
                      minLength: { value: 2, message: 'Debe ingresar la descripción del video.' }
                    })}
                    id="outlined-multiline-flexible"
                    label="Descripción"
                    multiline
                    rows={4}
                    error={!!errors?.Description}
                    onChange={(e) => handleChange(e)}
                  fullWidth
                  style={FormInputStyle}
                  inputProps={{ style: { fontSize: 12 }}}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputLabelProps={{ shrink: true }}
                      size="small"
                    />
                  )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <FormControl style={{ width: '100%' }} margin="dense">
                  <DateTimePicker
                    fullWidth
                    label="Fecha Publicación"
                    value={eventDate || null}
                    style={FormInputStyle}
                    onChange={handleChangeEventDate}
                    disablePast
                    inputProps={{ style: { fontSize: 12 }}}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{ shrink: true }}
                        size="small"
                      />
                    )}
                  />
                </FormControl>
              </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <FormControl style={{ width: '100%' }} margin="dense">
                <MuiChipsInput
                  value={chips}
                  onChange={handleChangeChip}
                  size="small"
                  variant="outlined"
                  placeholder='Escribe el nombre de una etiqueta y presiona Enter'
                  inputProps={{ style: { fontSize: 12 } }}
                  label="Etiquetas"
                />
                </FormControl>
                <LabelsTrends />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <StyleDragArea>
                  <div className="image-upload-wrap">
                    <input type="file" onChange={(e) => handleChange(e)} accept="video/*" name="file" className='file-upload-input'/>
                    <div className="text-information">
                      <h4 style={FormLabel}>Seleccione el video</h4>
                    </div>
                  </div>
                </StyleDragArea>
              </Grid>
              <Grid item xs={12} sm={12} md={12} style={{ marginTop: 15 }}>
                {videoDetails.file != null && <video controls width={460} height="230" src={uploadVideo}></video>}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} sx={{ marginTop: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <Button variant="contained" type="submit" style={PrimaryButton}>Subir</Button>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Grid>
  )
}