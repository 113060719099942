import React, { useState } from 'react';
import { AppContext } from './AppContext';

export const AppProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [userInfo, setUserInfo] = useState();
  const [requestToken, setRequestToken] = useState();
  const [, setSelectedOrganization] = useState();
  const [organizations, setOrganizations] = useState([]);
  const [, setRole] = useState([]);
  const [isIdle, setIsIdle] = useState(false);
  const [openSidebar, setOpenSidebar] = useState(true);
  const [refreshCalendar, setRefreshCalendar] = useState(false);
  const [refreshGrid, setRefreshGrid] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [formAction, setFormAction] = useState();
  const [calendarDefaultView, setCalendarDefaultView] = useState('month');
  const [loadingPage, setLoadingPage] = useState();

  const setCurrentUser = (user) => {
    setUser(user);
    setOrganizations(Object.entries(user?.organization_ids || []));
    localStorage.setItem('user', JSON.stringify(user));
  };

  const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem('user'));
  };

  const setCurrentOrganization = (organizationId) => {
    setSelectedOrganization(organizationId);
    localStorage.setItem('selectedOrganization', JSON.stringify(organizationId));
  };

  const getCurrentOrganization = () => {
    return localStorage.getItem('selectedOrganization') !== 'undefined'
      ? JSON.parse(localStorage.getItem('selectedOrganization'))
      : undefined;
  };

  const setCurrentToken = (token) => {
    setRequestToken(token);
    localStorage.setItem('token', JSON.stringify(token));
  };

  const getCurrentToken = () => {
    return localStorage.getItem('token') !== 'undefined'
      ? JSON.parse(localStorage.getItem('token'))
      : undefined;
  };

  const setCurrentRole = (role) => {
    setRole(role);
    localStorage.setItem('role', JSON.stringify(role));
  };

  const getCurrentRole = () => {
    return localStorage.getItem('role') !== 'undefined'
      ? JSON.parse(localStorage.getItem('role'))
      : undefined;
  };

  return (
    <AppContext.Provider
      value={{
        user,
        organizations,
        requestToken,
        isIdle,
        openSidebar,
        refreshCalendar,
        refreshGrid,
        openDrawer,
        userInfo,
        formAction,
        calendarDefaultView,
        loadingPage,
        setRefreshCalendar,
        setRefreshGrid,
        setCurrentToken,
        getCurrentToken,
        setOrganizations,
        setCurrentUser,
        getCurrentUser,
        setCurrentOrganization,
        getCurrentOrganization,
        setCurrentRole,
        getCurrentRole,
        setIsIdle,
        setOpenSidebar,
        setOpenDrawer,
        setUserInfo,
        setCalendarDefaultView,
        setFormAction,
        setLoadingPage
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
