import { AxiosServicePost } from "common/services";

const baseUrl = process.env.REACT_APP_COVACHA_SCHEDULE;
const apiUrl = 'v1/linkedin';
export const GetAccessToken = async ({ params, token }) => {
  return await AxiosServicePost(baseUrl, apiUrl, params, token);
};

export const SharePost = async ({ params, token }) => {
  return await AxiosServicePost(baseUrl, apiUrl+'/post', params, token);
}

export const ShareArticle = async ({ params, token }) => {
  return await AxiosServicePost(baseUrl, apiUrl+'/post-article', params, token);
}

export const ShareImage = async ({ params, token }) => {
  return await AxiosServicePost(baseUrl, apiUrl+'/post-image', params, token);
}

export const ShareVideo = async ({ params, token }) => {
  return await AxiosServicePost(baseUrl, apiUrl+'/post-video', params, token);
}