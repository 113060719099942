import React, { useContext } from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import { TabsFilterContext } from 'contexts';

export const Toolbar = ({ tabs }) => {
  const { filter, setFilter } = useContext(TabsFilterContext);

  const handleTabChange = (event, newValue) => {
    setFilter(newValue);
  };

  return (
    <Box sx={{ width: '70%' }}>
      <Tabs
        onChange={handleTabChange}
        value={filter}
        aria-label="Tabs where selection follows focus"
        selectionFollowsFocus
        TabIndicatorProps={{ style: { backgroundColor: '#2CA7A8' } }}
      >
        {tabs.options.map((tab) => (
          <Tab
            key={tab.id}
            label={tab.label}
            style={{ backgroundColor: tab.background, fontSize: 14, color: '#2CA7A8' }}
            value={parseInt(tab.value)}
          />
        ))}
      </Tabs>
    </Box>
  );
};
