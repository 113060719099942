import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Button, CardActions, Typography } from '@mui/material';
import { StyledCard, OrganizationCard, OrganizationCardContent } from 'common/styles';
import { AppContext } from 'contexts';
import { useUserOrganizations } from 'common/hooks';
import { GetOrganizationByIdService } from 'modules/organizations/services';

export const OrganizationSelector = () => {
  const { setCurrentOrganization } = useContext(AppContext);
  const userOrganizations = useUserOrganizations();
  const navigate = useNavigate();

  const handleSelectOrganization = (value) => {
    GetOrganizationByIdService({ id: value })
    .then((response) => {
      setCurrentOrganization(response);
    })
    .catch((error) => {
      console.log(error);
    });
    navigate('/calendario');
  };

  return (
    <StyledCard elevation={0}>
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: 16, marginBottom: 2 }}>
            Seleccione la empresa con la que desea trabajar:
          </Typography>
        </Grid>
        {userOrganizations?.map((organization) => (
          <Grid item xs={12} sm={6} md={3} key={'g' + organization.id}>
            <OrganizationCard key={'c' + organization.id}>
              <OrganizationCardContent>
                <Typography variant="h5" align="center">
                  <b>{organization.name}</b>
                </Typography>
              </OrganizationCardContent>
              <CardActions sx={{ justifyContent: 'center' }}>
                <Button
                  variant="contained"
                  size="small"
                  style={{ backgroundColor: '#053C5E' }}
                  onClick={() => handleSelectOrganization(organization.id)}
                >
                  Seleccionar
                </Button>
              </CardActions>
            </OrganizationCard>
          </Grid>
        ))}
      </Grid>
    </StyledCard>
  );
};
