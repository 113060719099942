import React from 'react';
import { Dialog, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';

export const ConfirmModal = ({ message, open, onClose, handleCloseModal, onConfirm }) => {
  return (
    <>
      <Dialog open={open} onClose={onClose} sx={{ '& .MuiBackdrop-root': { backgroundColor: 'rgba(0, 0, 0, 0.2)' } }}>
        <DialogContent>
          <DialogContentText sx={{ fontWeight: 'bold', textAlign: 'center' }}>
            {message?.length > 0 ? message : '¿Está seguro que desea eliminar?'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="small"
            onClick={handleCloseModal}
            style={{ marginLeft: '20%', fontSize: 10 }}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            size="small"
            color="error"
            onClick={onConfirm}
            style={{ marginLeft: '15%', marginRight: '20%', fontSize: 10 }}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
