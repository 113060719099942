import React, { useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Amplify } from 'aws-amplify';
import { Grid, Paper, TextField, Button, Typography, Divider } from '@mui/material';
import { HexColorPicker } from 'react-colorful';
import { useNavigate, useParams } from 'react-router-dom';
import { awsconfig } from 'common/config';
import { SubmitButton, SubmitSecondaryButton, FormActions, PageTitle, StyledDivider } from 'common/styles';
import { useSnackbar } from 'notistack';
import { AuthErrorHandler } from 'common/utils';
import { CreateMarketingCategoryService, GetMarketingCategoryByIdService, UpdateMarketingCategoryService } from '../services';
import { useCurrentOrganization, useCurrentToken } from 'common/hooks';
import { AppContext } from 'contexts';
import { CustomCircularProgress } from 'common/components';

Amplify.configure(awsconfig);

export function MarketingCategoryForm() {
  const { formAction, setFormAction } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(true);
  const [color, setColor] = useState("#aabbcc");
  const { enqueueSnackbar } = useSnackbar();
  const { register, handleSubmit, formState: { errors }, setValue } = useForm();
  const requestToken = useCurrentToken();
  const organizacionId = useCurrentOrganization()
  const navigate = useNavigate();
  const params = useParams();

  const onSubmit = async (data, e) => {
    e.preventDefault();
    data.OrganizationId = organizacionId;
    data.Color = color;
    if (formAction == 'create') {
      CreateMarketingCategoryService({ params: data, token: requestToken })
      .then(() => {
        navigate('/categorias-contenido');
        enqueueSnackbar('Categoria registrada con exito!', { variant: `success` });
      })
      .catch((error) => {
        enqueueSnackbar(AuthErrorHandler.getErrorMessage(error), { variant: `error` });
      });
    }
    else {
      data.Id = params?.id;
      UpdateMarketingCategoryService({
        id: data.Id,
        params: data,
        token: requestToken
      })
      .then(() => {
        navigate('/categorias-contenido');
        enqueueSnackbar('Categoria actualizada con exito!', { variant: `success` });
      })
      .catch((error) => {
        enqueueSnackbar(AuthErrorHandler.getErrorMessage(error), { variant: `error` });
      });
    }
  };

  const handleSelectColor = (value) => {
    setColor(value);
  };

  useEffect(() => {
    if (params?.id === undefined) {
      setFormAction('create');
      setIsLoading(false);
      return;
    }

    GetMarketingCategoryByIdService({ id: params?.id, token: requestToken })
    .then((data) => {
      setFormAction('update');
      setValue('Name', data.Name);
      setValue('Color', data.Color);
      setColor(data.Color);
      setIsLoading(false);
    })
    .catch((error) => {
      enqueueSnackbar(`Se ha producido un error. ${error}`, { variant: `error` });
    });
  }, [params]);

  return (
    <>
      { isLoading ? (
        <CustomCircularProgress />
      ):(
        <form onSubmit={handleSubmit(onSubmit)}>
          <Paper elevation={2} sx={{ p: 2 }}>
            <Typography style={PageTitle}>Nueva Categoria</Typography>
            <Divider sx={StyledDivider} />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  label="Nombre"
                  type="text"
                  variant="outlined"
                  color="primary"
                  margin="dense"
                  {...register('Name', {
                    required: { value: true, message: 'Campo Requerido' }
                  })}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ style: { fontSize: 12 } }}
                  error={!!errors?.Name}
                  helperText={errors?.Name?.message}
                  style={{ marginLeft: '0.5%', width: '99%' }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}></Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  label="Color"
                  type="text"
                  variant="outlined"
                  color="primary"
                  margin="dense"
                  {...register('Color')}
                  value={color}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ style: { fontSize: 12 } }}
                  error={!!errors?.Color}
                  helperText={errors?.Color?.message}
                  style={{ marginLeft: '0.5%', width: '99%' }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <HexColorPicker color={color} onChange={(e) => handleSelectColor(e)} />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4} md={4} style={FormActions}>
              <Button variant="contained" size="small" type="submit" style={SubmitButton}>
                Guardar
              </Button>
              <Button
                variant="contained"
                size="small"
                style={SubmitSecondaryButton}
                sx={{ marginLeft: 1 }}
                onClick={() => navigate('/categorias-contenido')}
              >
                Cancelar
              </Button>
            </Grid>
          </Paper>
        </form>
      )}
    </>
  );
}
