export const leadCategoryLabel = Object.freeze({
  new: "Nuevo",
  in_progress: "En Progreso",
  on_sale: "En Venta",
  off_sale: "Sin Venta"
});

export const leadCategoryColorLabel = Object.freeze({
  new: '#E65C4F',
  in_progress: '#0295A9',
  on_sale: '#5B8291',
  off_sale: '#434a43'
});

export const leadCategories = [
  { name: 'Nuevo', color: '#E65C4F' },
  { name: 'En Progreso', color: '#0295A9' },
  { name: 'En Venta', color: '#5B8291' },
  { name: 'Sin Venta', color: '#434a43' }
];

export const leadSalesStatusColors = [
  '#BD0101',
  '#E65C4F',
  '#E85C4F',
  '#EA5C4F',
  '#EE5C4F',
  '#FE5C4F',
  '#FF5C4F',
  '#F07800',
  '#01BD91',
  '#01BDA1',
  '#01BDB3',
  '#01CEC5',
  '#01BDC5',
  '#01CFD5',
  '#01B4BD',
  '#0176BD',
  '#014EBD',
  '#3AB3C1',
  '#5B8291',
  '#5B82A6',
  '#5BAACA',
  '#5BAACF',
  '#5BAADF',
  '#5BAAEF',
  '#5BCCEF',
  '#5BAAAA',
  '#5B8AC6',
  '#909B90',
  '#869186',
  '#727D72',
  '#636D63',
  '#576057',
  '#4D554D',
  '#434a43',
  '#3AB3C1'
];