import {
  AxiosServicePost,
  AxiosServiceUpdate,
  AxiosServiceGet,
  AxiosServiceDelete
} from 'common/services/AxiosBaseService';

const baseUrl = process.env.REACT_APP_COVACHA_CORE;
const apiUrl = 'v1/users';
const projectsApiUrl = 'v1/my_projects';

export const GetUsersService = async ({ params, token }) => {
  return await AxiosServiceGet(baseUrl, apiUrl, params, token);
};

export const GetUserByIdService = async ({ id, token }) => {
  return await AxiosServiceGet(baseUrl, `${apiUrl}/${id}`, null, token);
};

export const GetUserProjectsService = async ({ params, token }) => {
  return await AxiosServiceGet(baseUrl, `${projectsApiUrl}`, params, token);
};

export const CreateUserService = async (params) => {
  return await AxiosServicePost(baseUrl, apiUrl, params);
};

export const UpdateUserNotifiactionService = async ({ id, params, token }) => {
  return await AxiosServiceUpdate(baseUrl, `${apiUrl}/${id}`, params, token);
};

export const DeleteUserService = async (params) => {
  return await AxiosServiceDelete(baseUrl, `${apiUrl}/${params.users.id}`);
};

export const UpdateUserPasswordService = async ({ id, params, token }) => {
  return await AxiosServicePost(baseUrl, `${apiUrl}/${id}`, params, token);
};


export const UpdateUserService = async ({ id, params, token }) => {
  return await AxiosServiceUpdate(baseUrl, `${apiUrl}/${id}`, params, token);
};
