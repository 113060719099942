import React, { useContext } from "react";
import { useLinkedIn }  from "react-linkedin-login-oauth2";
import { useSnackbar } from 'notistack';
import { GetAccessToken } from "../services/LinkedinService";
import { useCurrentOrganization, useCurrentToken } from "common/hooks";
import { AppContext } from "contexts";
import { Button } from "@mui/material";
import { PrimaryButton } from "common/styles";
import { GetOrganizationByIdService , UpdateOrganizationService} from "modules/organizations/services";

export const LinkedinButtonForLogin = () => {
  const { setCurrentOrganization } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  let currentOrganization = useCurrentOrganization();
  const requestToken = useCurrentToken();

  const { linkedInLogin } = useLinkedIn({
    clientId: `${process.env.REACT_APP_LINKEDIN_CLIENT_ID}`,
    redirectUri: `${window.location.origin}/linkedin`,
    scope: ['r_emailaddress', 'r_liteprofile', 'w_member_social'],
    onSuccess: (codeResponse) => {
      getAccessToken(codeResponse);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const updateOrganization = (data) => {
    UpdateOrganizationService({
      id: data.Id,
      params: data,
      token: requestToken
    })
    .then(() => {
      enqueueSnackbar('Linkedin token registrado con exito', { variant: `success` });
      setCurrentOrganization(data);
    })
  };

  const updateLinkedinToken = (linkedinData) => {
    GetOrganizationByIdService({
      id: currentOrganization?.Id,
      token: requestToken
    })
    .then((data) => {
      if (data !== undefined) {
        const linkedinProfile = {
          ProfileId: linkedinData.profile.id,
          AccountInfo: linkedinData.profile.localizedFirstName + ', ' + linkedinData.profile.localizedLastName
        };
        data.LinkedinAccessToken = linkedinData?.access_token;
        data.LinkedinProfile = linkedinProfile;
        updateOrganization(data);
      }
    });
  };

  const getAccessToken = (code) => {
    const params = { code: code };
    GetAccessToken({ params: params, token: requestToken })
    .then((response) => {
      if (response.statusCode === 200) {
        updateLinkedinToken(response.body);
      }
    });
  }

  return (
      <Button style={PrimaryButton} onClick={linkedInLogin}>
        Autorizar
      </Button>
  );
}