import { createContext } from 'react';

export const CalendarContext = createContext({
  eventAction: 'create',
  setEventDate: () => {},
  setRefreshCalendar: () => {},
  setCurrentEvent: () => {},
  setEventAction: () => {},
  setEvents: () => {},
  setEventFilter: () => {}
});
