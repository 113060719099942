export const NotesPrimaryButton = {
  fontSize: 9,
  fontWeight: 'bold',
  paddingBottom: 5,
  marginBottom: 10,
  backgroundColor: '#053C5E'
}

export const NotesSecondaryButton = {
  fontSize: 9,
  fontWeight: 'bold',
  marginBottom: 1,
  backgroundColor: '#EDF0F2',
  color: '#333333',
}

export const NotesSuccessButton = {
  fontSize: 9,
  fontWeight: 'bold',
  marginBottom: 1,
  backgroundColor: '#14964C',
  color: '#333333',
}