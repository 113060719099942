import {
  AxiosServiceGet,
  AxiosServiceUpdate,
  AxiosServicePost, 
  AxiosServiceDelete
} from 'common/services/AxiosBaseService';

const baseUrl = process.env.REACT_APP_COVACHA_SCHEDULE;
const apiUrl = 'v1/schedules';

export const GetSchedulesService = async ({ params, token }) => {
  return await AxiosServiceGet(baseUrl, apiUrl, params, token);
};

export const GetScheduleByIdService = async ({ id, token }) => {
  return await AxiosServiceGet(baseUrl, `${apiUrl}/${id}`, null, token);
};

export const CreateScheduleService = async ({ params, token }) => {
  return await AxiosServicePost(baseUrl, `${apiUrl}`, params, token);
};

export const DeleteScheduleService = async ({ id, token }) => {
  return await AxiosServiceDelete(baseUrl, `${apiUrl}/${id}`, token);
};

export const UpdateScheduleService = async ({ id, params, token }) => {
  return await AxiosServiceUpdate(baseUrl, `${apiUrl}/${id}`, params, token);
};