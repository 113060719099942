export class CalendarCustomFunctions {
  static PreprocessMarketingContentEvent(data, categories) {
    let eventCategory;
    let result = [];
    data?.map((row) => {
      eventCategory = categories?.filter((category) => category.Id === row.CategoryId)?.shift();
      result.push({
        id: row.Id,
        title: row.Title,
        category: eventCategory?.Name,
        categoryColor: eventCategory?.Color,
        assignedTo: row.AssignedTo,
        start: new Date(row.PublishAt),
        end: new Date(row.PublishAt),
        allDay: false,
        info: row
      });
    });
    return result;
  }

  static PreprocessSchedulesEvent(data, statuses) {
    let eventStatus;
    let result = [];
    data?.map((row) => {
      eventStatus = statuses?.filter((status) => status.name === row.EventStatus)?.shift();
      result.push({
        id: row.Id,
        title: row.Copy,
        start: new Date(row.PublishAt),
        end: new Date(row.PublishAt),
        allDay: false,
        status: row.Status,
        eventStatus: row.EventStatus,
        statusColor: eventStatus?.color,
        info: row
      });
    });
    return result;
  }
}